import React, { useState, useEffect } from 'react';
import { Accordion, ListGroup, Button, Row, Col } from 'react-bootstrap';
import { FaArrowAltCircleLeft, FaArrowAltCircleRight } from "react-icons/fa";
import httpClient from '../../services/httpClient.ts';
import API_URLS from '../../constants/apiUrls.ts';
import { useTranslation } from 'react-i18next';
import { ToastContainer, toast } from 'react-toastify';
import { PaginationTableColumns } from '../../constants/commonData.js'

interface Column {
    field: string;
    label: string;
    visible: boolean;
}

const ExchangeList: React.FC = () => {
    const {t} = useTranslation()
    const [studyDetailsColumns, setStudyProgramsDetailsColumns] = useState<Column[]>([]);
    const [selectedItem, setSelectedItem] = useState<Column | null>(null);

    const handleSelectItem = (item: Column) => {
        setSelectedItem(item);
    };

    const postTableSettings = () => {
        const data = {
            tableName: "paginationTable",
            settings: studyDetailsColumns
        }
        httpClient({
            method: "post",
            url: API_URLS.addTableSettings,
            data: JSON.stringify(data),
        })
            .then(({ result, error }) => {
                if(result) {
                    toast.success(result.message)
                }
            })
    }

    const handleTransfer = (direction: 'left' | 'right') => {
        if (selectedItem) {
            const itemToTransfer = selectedItem;
            const updatedItems = studyDetailsColumns.map(item =>
                item.field === itemToTransfer.field
                    ? { ...item, visible: direction === 'right' }
                    : item
            );
            setStudyProgramsDetailsColumns(updatedItems);
            setSelectedItem(null); // Clear selected item
        }
    };

    const generateColumns = (length: number, labels: string[], fields: string[], visibility: boolean[]): Column[] => {
        if (length !== labels.length || length !== fields.length || length !== visibility.length) {
            throw new Error('Length of labels, fields, and visibility arrays must be equal');
        }

        return new Array(length).fill(null).map((_, index) => ({
            field: fields[index],
            label: labels[index],
            visible: visibility[index],
        }));
    };

    useEffect(() => {
        let url = API_URLS.getTableSettings;
        httpClient({
            method: "get",
            url
        }).then(({ result, error }) => {
            if (result?.status) {
                setStudyProgramsDetailsColumns(result?.response?.tableSettings?.educationInsights?.paginationTable)
            }else{
                const paginationTableVisibility = new Array(PaginationTableColumns.labels.length).fill(true); // Column visibility based on initial status
                const columns = generateColumns(PaginationTableColumns.labels.length, PaginationTableColumns.languageKeys, PaginationTableColumns.fields, paginationTableVisibility);
                setStudyProgramsDetailsColumns(columns);
            }
        })
    }, [t]);

    const visibleItems = studyDetailsColumns?.filter(item => item.visible);
    const nonVisibleItems = studyDetailsColumns?.filter(item => !item.visible);

    return (
        <>
        <ToastContainer/>
        <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0" style={{marginTop:"10px"}}>
                <Accordion.Header className="custom-accordion" style={{ display: 'flex', justifyContent: 'space-between', width: '100% !important'}}>{t("settings.tabTableSettings.accordionTitle.tableStudyProgram")}</Accordion.Header>
                <Accordion.Body>
                    <Row style={{ padding: "20px 0" }} className="align-items-start">
                        <Col md={5}>
                            <h5>{t("settings.tabTableSettings.accordionContent.columns")}</h5>
                            <div style={{ height: '310px', overflowY: 'auto' }}>
                                <ListGroup className='table-list-item'>
                                    {visibleItems?.map(item => (
                                        <ListGroup.Item
                                            key={item.field}
                                            active={item.field === selectedItem?.field}
                                            onClick={() => handleSelectItem(item)}
                                        >
                                            {t(item.label)}
                                        </ListGroup.Item>
                                    ))}
                                </ListGroup>
                            </div>
                        </Col>

                        <Col md={2} style={{ height: '300px' }} className="d-flex flex-column align-items-center justify-content-center">
                            <FaArrowAltCircleLeft
                                style={{ fontSize: "30px", cursor: "pointer", marginBottom: "10px",
                                    opacity: !selectedItem ? 0.5 : 1,
                                }}
                                onClick={() => {
                                    if (selectedItem) {
                                      handleTransfer('right');
                                    }
                                  }}
                            />
                            <FaArrowAltCircleRight
                                style={{ fontSize: "30px", cursor: "pointer", marginBottom: "10px",
                                    opacity: !selectedItem ? 0.5 : 1,
                                }}
                                
                                onClick={() => {
                                    if (selectedItem) {
                                      handleTransfer('left');
                                    }
                                  }}
                               
                            />
                        </Col>
                        <Col md={5}>
                            <h5>{t("settings.tabTableSettings.accordionContent.hideColumns")}</h5>
                            <div style={{ height: '310px', overflowY: 'auto' }}>
                                <ListGroup>
                                    {nonVisibleItems?.map(item => (
                                        <ListGroup.Item
                                            key={item.field}
                                            active={item.field === selectedItem?.field}
                                            onClick={() => handleSelectItem(item)}
                                        >
                                            {t(item.label)}
                                        </ListGroup.Item>
                                    ))}
                                </ListGroup>
                            </div>
                        </Col>
                    </Row>
                    <Row style={{padding: "0 !important"}} className='d-flex justify-content-end'>
                        <Button className="btn" style={{width:"150px", padding: "0 !important"}} onClick={postTableSettings}>{t("settings.tabTableSettings.buttonTitle")}</Button>
                    </Row>

                </Accordion.Body>
            </Accordion.Item>

        </Accordion>
        </>
    );
};

export default ExchangeList;




