import React, { useRef, useEffect, useState, useTransition } from "react";
import Header from "./components/Header.tsx";
import CanadaMapJson from "../../components/CanadaMap/CanadaMap.json";
import API_URLS from "../../constants/apiUrls.ts";
import httpClient from "../../services/httpClient.ts";
import BarChart from "../../components/charts/BarChart.tsx";
import ComparisonBarChart from "../../components/charts/ComparisonBarChart.tsx";
import {
  Button,
  Card,
  ListGroup,
  Row,
  Col,
  Container,
  Form,
  OverlayTrigger,
  Tooltip
} from "react-bootstrap";
import CommonHeader from "../../components/Header.tsx";
import ChartJsBarChart from "../../components/charts/ChartJsBarChart.js";
import { useLocation } from "react-router-dom";
import { Loader } from "../../components/Loader.tsx";
import CompareCharts from "../../components/CompareCharts.tsx";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
import { educationFields, employmentFields } from "../../constants/commonData.js";
import { FaQuestionCircle } from 'react-icons/fa'
import ReactPlayer from "react-player";
import { BiSolidVideos } from "react-icons/bi";
import HelpTour from "../../components/HelpTour.tsx";
// const steps = [

//   {
//     target: ".comparison-filters",
//     content: "You may select from the dropdown categories available to you to adjust data displayed. You may select from the different Size of Establishments or Province or Territory selections, and also adjust for which Period and Year the data was collected from.",
//     disableBeacon: true
//   },
//   {
//     target: ".comparsion_button",
//     content: "Clicking on any of the Sector bar graph charts will open the Insights tab to your right for more valuable information across the Sectors and their related sub-sectors as well.",
//   },
//   {
//     target: ".clear_btn",
//     content:
//       "You may click the 'Clear All' selection button in blue to reset the entry fields from your dropdown selections."
//   },
// ];

const Comparison = ({ field, steps, joyrideStatus, setJoyrideStatus }) => {
  interface Period {
    month: string;
    value: number;
  }

  interface SelectedYear {
    year: number;
    period: Period[];
  }
  const { t } = useTranslation()
  const location = useLocation();
  const [stepStates, setStepStates] = useState(
    steps?.map(() => ({ showVideo: false })) || []
  );
  const settings = JSON.parse(localStorage.getItem('user'))?.settings
  const [selected, setSelected] = useState({ id: "canada", value: "CANADA" });
  const [userSetting, setUserSetting] = useState(settings)
  const [selectedSheetType, setSelectedSheetType] = useState(0);
  const [businessTypeList, setBusinessTypeList] = useState<any>([]);
  const [establishmentList, setEstablishmentList] = useState<any>([]);
  const [geographyList, setGeographyList] = useState<any>([]);
  const [businessCountYears, setBusinessCountYears] = useState<any>([])
  const [employmentCountYears, setEmploymentCountYears] = useState([])
  const [areaOfStudy, setAreaofStudy] = useState<any>([])
  const [levelOfInstruction, setLevelOfInstruction] = useState<any>([])
  const [establishmentBusinessCount, setEstablishmentBusinessCount] = useState<
    any
  >([]);
  const [
    subEstablishmentBusinessCount,
    setSubEstablishmentBusinessCount,
  ] = useState<any>([]);

  const [response, setResponse] = useState<any>([]);
  const [mapJson, setMapJson] = useState<any>([...CanadaMapJson]);
  const [selectedField, setSelectedField] = useState(educationFields[0].value)
  const [selectedStudyField, setSelectedStudyField] = useState("all")
  const [selectedStudyField2, setSelectedStudyField2] = useState("all")
  const [selectedLevelOfInstruction, setSelectedLevelOfInstruction] = useState("")
  const [selectedLevelOfInstruction2, setSelectedLevelOfInstruction2] = useState("")
  const [selectedSector, setSelectedSector] = useState("");
  const [selectedBarIndex, setSelectedBarIndex] = useState(undefined);
  const [selectedGeography, setSelectedGeography] = useState("");
  const [selectedGeography2, setSelectedGeography2] = useState("");
  const [selectedEstablishment, setSelectedEstablishment] = useState("");
  const [selectedEstablishment2, setSelectedEstablishment2] = useState("");
  const [employmentSize, setEmploymentSize] = useState("");
  const [employmentSize2, setEmploymentSize2] = useState("");
  const [selectedPeriod, setSelectedPeriod] = useState<1 | 2 | undefined>(undefined);
  const [selectedPeriod2, setSelectedPeriod2] = useState<1 | 2 | undefined>(undefined);
  const [selectedEstablishmentYear, setSelectedEstablishmentYear] = useState<SelectedYear | null>(null);;
  const [selectedEstablishmentYear2, setSelectedEstablishmentYear2] = useState<SelectedYear | null>(null);;
  const [selectedEmploymentYear, setSelectedEmploymentYear] = useState(null);
  const [selectedEmploymentYear2, setSelectedEmploymentYear2] = useState(null);
  const [selectedSubField, setSelectedSubField] = useState("")
  const [selectedRange, setSelectedRange] = useState("")
  const [selectedRange2, setSelectedRange2] = useState("")
  const [selectedGender, setSelectedGender] = useState("men")
  const [selectedGender2, setSelectedGender2] = useState("men")
  const [compareRes, setCompareRes] = useState({});
  const [filterOptionsLoaded, setFilterOptionsLoaded] = useState(false);
  const [show, showLoader] = useState(false);
  const [joyrideSteps, setSteps] = useState([])
  const [helpRun, setHelpRun] = useState(false)
  const [colors, setColors] = useState<any>([
    "#de9b61", //  3111:
    "#fbb518", // 3112:
    "#d460a2", // 3113:
    "#ef4b32", // 3114:
    "#27ade3", // 3115:
    "#e91c4d", // 3116:
    "#113f70", // 3117:
    "#663300", // 3118:
    "#119448", // 3119:
    "#804795", // 3121:
    "#0f5b2e", // 3123:
  ]);

  const handleHelpTour = (value) => {
    const { action, index, origin, status, type } = value;

    if (action === 'reset' || action === 'close') {
      setHelpRun(false)
      setJoyrideStatus(false)
      setStepStates(stepStates?.map(() => ({ showVideo: false })) || [])
    }
  }

  const handleChange = (event) => {
    let name = event.target.name;
    let val = event.target.value;
    if (name === "employmentSize") setEmploymentSize(val);
    if (name === "employmentSize2") setEmploymentSize2(val);
    if (name === "selectedEstablishmentYear") setSelectedEstablishmentYear(JSON.parse(val));
    if (name === "selectedEstablishmentYear2") setSelectedEstablishmentYear2(JSON.parse(val));
    if (name === "selectedEmploymentYear") setSelectedEmploymentYear(val);
    if (name === "selectedEmploymentYear2") setSelectedEmploymentYear2(val);
    if (name === "selectedGeography") setSelectedGeography(val);
    if (name === "selectedGeography2") setSelectedGeography2(val);
    if (name === "establishment") setSelectedEstablishment(val);
    if (name === "establishment2") setSelectedEstablishment2(val);
    if (name === "selectedPeriod") setSelectedPeriod(Number(val));
    if (name === "selectedPeriod2") setSelectedPeriod2(Number(val));
    if (name === 'field') setSelectedField(val);
    if (name === 'studyField') setSelectedStudyField(val === "all" ? "all" : JSON.parse(val))
    if (name === 'studyField2') setSelectedStudyField2(val === "all" ? "all" : JSON.parse(val))
    if (name === 'levelOfInstruction') setSelectedLevelOfInstruction(val)
    if (name === 'levelOfInstruction2') setSelectedLevelOfInstruction2(val)
    if (name === 'subField') setSelectedSubField(val)
    if (name === 'gender') setSelectedGender(val)
    if (name === 'gender2') setSelectedGender2(val)
    if (name === 'ageRange') setSelectedRange(val)
    if (name === 'ageRange2') setSelectedRange2(val)
  };

  const clearAll = () => {
    setCompareRes({})
    if (field === "establishmentCount") {
      setEmploymentSize("");
      setSelectedEstablishmentYear(businessCountYears[0]);
      setSelectedPeriod(businessCountYears?.[0]?.period?.length > 1 ? 2 : 1 as 1 | 2);
      setSelectedGeography(JSON.stringify(geographyList.filter((geo) => geo?.name == "canada")[0]));
      setEmploymentSize2("");
      setSelectedEstablishmentYear2(businessCountYears[0]);
      setSelectedPeriod2(businessCountYears?.[0]?.period?.length > 1 ? 2 : 1 as 1 | 2);
      setSelectedGeography2(JSON.stringify(geographyList.filter((geo) => geo?.name == "canada")[0]));
    }
    if (field === "employmentInsights") {
      if (selectedField === "provinceTerritory") {
        setSelectedGeography(JSON.stringify(geographyList.filter((geo) => geo?.name == "canada")[0]));
        setSelectedGeography2(JSON.stringify(geographyList.filter((geo) => geo?.name == "canada")[0]));
      }
      if (selectedField === "sector") {
        setSelectedEstablishment(JSON.stringify(establishmentList[0]));
        setSelectedEstablishment2(JSON.stringify(establishmentList[0]));
      }
      if (selectedField === "year") {
        setSelectedEmploymentYear(employmentCountYears[0]);
        setSelectedEmploymentYear2(employmentCountYears[0]);
        setSelectedSubField('geography')
      }
      if (selectedField === "breakdown") {
        setSelectedSubField("")
        setSelectedEstablishment(JSON.stringify(establishmentList[0]));
        setSelectedEstablishment2(JSON.stringify(establishmentList[0]));
        setSelectedRange('under30')
        setSelectedRange2('under30')
        setSelectedGender('men')
        setSelectedGender2('men')
      }

    }
    if (field === "educationalInsights") {
      setSelectedField(educationFields[0].value)
      setSelectedStudyField('all')
      setSelectedStudyField2('all')
    }

  };

  const getFilterOptions = async () => {
    showLoader(true);

    // Define all the API URLs
    const urls = {
      geographies: API_URLS.listGeographies,
      businessTypes: API_URLS.listBusinessTypes,
      establishments: API_URLS.listEstablishments,
      businessCountYears: API_URLS.businessCountYearsList,
      areaOfStudy: API_URLS.listAreaOfStudy,
      levelOfInstruction: API_URLS.listLevelOfInstruction,
      employmentCountYears: API_URLS.employmentDataYears
    };

    try {
      // Execute all API calls concurrently
      const [geographiesResponse, businessTypesResponse, establishmentsResponse, businessCountYearsResponse, areaOfStudyResponse, levelOfInstructionResponse, employmentCountYearsResponse] = await Promise.all([
        httpClient({ method: "get", url: urls.geographies }),
        httpClient({ method: "get", url: urls.businessTypes }),
        httpClient({ method: "get", url: urls.establishments.replace(false, true) }),
        httpClient({ method: "get", url: urls.businessCountYears }),
        httpClient({ method: "get", url: urls.areaOfStudy }),
        httpClient({ method: "get", url: urls.levelOfInstruction }),
        httpClient({ method: "get", url: urls.employmentCountYears }),
      ]);

      // Handle geographies response
      if (geographiesResponse.result?.geographies) {
        const sortedGeographies = geographiesResponse.result.geographies
          .filter((geo) => geo.name.toLowerCase() !== "geography")
          .sort((a, b) => a.name.localeCompare(b.name));
        const canada = sortedGeographies.filter((geo) => geo.name === "canada");
        const otherProvinces = sortedGeographies.filter((geo) => geo.name !== "canada");
        const finalSortedGeographies = [...canada, ...otherProvinces];

        setGeographyList(finalSortedGeographies);
        setSelectedGeography(userSetting?.comparison?.field1?.geography ? JSON.stringify(finalSortedGeographies.filter(geo => geo._id === userSetting?.comparison?.field1?.geography)[0]) : JSON.stringify(canada[0]));
        setSelectedGeography2(userSetting?.comparison?.field2?.geography ? JSON.stringify(finalSortedGeographies.filter(geo => geo._id === userSetting?.comparison?.field2?.geography)[0]) : JSON.stringify(canada[0]));
      }

      // Handle business types response
      if (businessTypesResponse.result?.businessTypes) {
        setBusinessTypeList([...businessTypesResponse.result.businessTypes]);
        setEmploymentSize(userSetting?.comparison?.field1?.employmentSize && userSetting?.comparison?.field1?.employmentSize !== 'null' ? JSON.stringify(businessTypesResponse.result?.businessTypes.filter(type => type._id === userSetting?.comparison?.field1?.employmentSize)[0]) : "");
        setEmploymentSize2(userSetting?.comparison?.field2?.employmentSize && userSetting?.comparison?.field2?.employmentSize !== 'null' ? JSON.stringify(businessTypesResponse.result?.businessTypes.filter(type => type._id === userSetting?.comparison?.field2?.employmentSize)[0]) : "");
      }

      // Handle establishments response
      if (establishmentsResponse.result?.establishments) {
        const establishments = establishmentsResponse.result.establishments.filter(item => item.name !== "Total, all industries")
        setEstablishmentList([...establishments]);
        setSelectedEstablishment(userSetting?.comparison?.field1?.establishment ? JSON.stringify(establishments.filter(item => item._id === userSetting?.comparison?.field1?.establishment)[0]) : JSON.stringify(establishments[0]));
        setSelectedEstablishment2(userSetting?.comparison?.field2?.establishment ? JSON.stringify(establishments.filter(item => item._id === userSetting?.comparison?.field2?.establishment)[0]) : JSON.stringify(establishments[0]));
      }

      // Handle establishments response
      if (businessCountYearsResponse.result?.years) {
        setBusinessCountYears([...businessCountYearsResponse.result?.years]);
        setSelectedEstablishmentYear(businessCountYearsResponse.result?.years[0])
        setSelectedEstablishmentYear2(businessCountYearsResponse.result?.years[0])
      }

      // Handle area of study response
      if (areaOfStudyResponse.result?.results) {
        setAreaofStudy([...areaOfStudyResponse.result?.results])
        setSelectedStudyField(userSetting?.comparison?.field1?.studyField && userSetting?.comparison?.field1?.studyField !== 'all' ? areaOfStudyResponse.result?.results.filter(item => item._id === userSetting?.comparison?.field1?.studyField)[0] : "all")
        setSelectedStudyField2(userSetting?.comparison?.field2?.studyField && userSetting?.comparison?.field2?.studyField !== 'all' ? areaOfStudyResponse.result?.results.filter(item => item._id === userSetting?.comparison?.field2?.studyField)[0] : "all")
      }
      if (levelOfInstructionResponse.result?.results) {
        setLevelOfInstruction([...levelOfInstructionResponse.result?.results])
        setSelectedLevelOfInstruction(levelOfInstructionResponse.result?.results[0])
        setSelectedLevelOfInstruction2(levelOfInstructionResponse.result?.results[0])
      }

      if (employmentCountYearsResponse.result?.years) {
        const yearArray = employmentCountYearsResponse.result?.years.reverse()
        setEmploymentCountYears(yearArray)
        setSelectedEmploymentYear(yearArray[0])
        setSelectedEmploymentYear2(yearArray[0])
      }

      setSelectedPeriod(businessCountYearsResponse.result?.years[0].period.length > 1 ? 2 : 1);
      setSelectedPeriod2(businessCountYearsResponse.result?.years[0].period.length > 1 ? 2 : 1);
      setSelectedGender(userSetting?.comparison?.field1?.gender ? userSetting?.comparison?.field1?.gender : "men")
      setSelectedGender2(userSetting?.comparison?.field2?.gender ? userSetting?.comparison?.field2?.gender : "men")
      setSelectedRange(userSetting?.comparison?.field1?.range ? userSetting?.comparison?.field1?.range : "all")
      setSelectedRange2(userSetting?.comparison?.field2?.range ? userSetting?.comparison?.field2?.range : "all")
      // setSelectedField(userSetting?.comparison?.compareField ? userSetting?.comparison?.compareField : "provinceTerritory")
      setSelectedSubField(userSetting?.comparison?.subField)
      showLoader(false);
      setFilterOptionsLoaded(true)
    } catch (error) {
      console.log(error)
    }
  }

  const capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const handleCompareCTA = async (e) => {
    e.preventDefault()
    let filterString = "?";
    let url: string
    if (field === 'establishmentCount') {
      if (employmentSize) filterString += `businessType1=${employmentSize === "" ? "" : employmentSize === null || employmentSize === "null" ? "null" : JSON.parse(employmentSize)._id}&`;
      if (selectedPeriod) filterString += `period1=${selectedPeriod}&`;
      if (selectedEstablishmentYear) filterString += `year1=${selectedEstablishmentYear?.year}&`;
      if (selectedGeography) filterString += `geography1=${JSON.parse(selectedGeography)._id}&`;
      if (employmentSize2) filterString += `businessType2=${employmentSize2 === "" ? "" : employmentSize2 === null || employmentSize2 === "null" ? "null" : JSON.parse(employmentSize2)._id}&`;
      if (selectedPeriod2) filterString += `period2=${selectedPeriod2}&`;
      if (selectedEstablishmentYear2) filterString += `year2=${selectedEstablishmentYear2?.year}&`;
      if (selectedGeography2) filterString += `geography2=${JSON.parse(selectedGeography2)._id}&`;

      filterString = filterString.slice(0, -1);
      const url = API_URLS.compareCharts + filterString;

      try {
        const { result, error } = await httpClient({ method: "get", url });
        if (error) {
          setCompareRes({});
        } else if (result?.count1.length && result?.count2.length) {
          setCompareRes(result);
        } else {
          Swal.fire({
            icon: "error",
            title: "Please change datafields to compare",
            toast: false,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      } catch (error) {
        console.error("Error:", error);
      }
    } else {
      if (field === "educationalInsights" && selectedField === 'provinceTerritory') {
        if (selectedField) filterString += `field=${selectedField}&`;
        if (selectedStudyField) filterString += `studyField=${selectedStudyField === "all" ? "all" : selectedStudyField?._id}&`;
        if (selectedStudyField2) filterString += `studyField2=${selectedStudyField2 === "all" ? "all" : selectedStudyField2?._id}&`;

        filterString = filterString.slice(0, -1);
        url = API_URLS.educationComparison + filterString;

        try {
          const { result, error } = await httpClient({ method: "get", url });
          if (error) {
            setCompareRes({});
          } else if (result?.count1.length && result?.count2.length) {
            setCompareRes(result);
          } else {
            Swal.fire({
              icon: "error",
              title: "Please change datafields to compare",
              toast: false,
              showConfirmButton: false,
              timer: 1500,
            });
          }
        } catch (error) {
          console.error("Error:", error);
        }

      } else if (field === "educationalInsights" && selectedField === 'levelInstruction') {
        if (selectedField) filterString += `field=${selectedField}&`;
        if (selectedStudyField) filterString += `studyField=${selectedStudyField === "all" ? "all" : selectedStudyField?._id}&`;
        if (selectedStudyField2) filterString += `studyField2=${selectedStudyField2 === "all" ? "all" : selectedStudyField2?._id}&`;

        filterString = filterString.slice(0, -1);
        url = API_URLS.educationComparison + filterString;

        try {
          const { result, error } = await httpClient({ method: "get", url });
          if (error) {
            setCompareRes({});
          } else if (result?.count1.length && result?.count2.length) {
            setCompareRes(result);
          } else {
            Swal.fire({
              icon: "error",
              title: "Please change datafields to compare",
              toast: false,
              showConfirmButton: false,
              timer: 1500,
            });
          }
        } catch (error) {
          console.error("Error:", error);
        }

      } else if (field === "educationalInsights" && selectedField === 'apprenticeOffered') {
        if (selectedField) filterString += `field=${selectedField}&`;
        if (selectedStudyField) filterString += `studyField=${selectedStudyField === "all" ? "all" : selectedStudyField?._id}&`;
        if (selectedStudyField2) filterString += `studyField2=${selectedStudyField2 === "all" ? "all" : selectedStudyField2?._id}&`;

        filterString = filterString.slice(0, -1);
        url = API_URLS.educationComparison + filterString;

        try {
          const { result, error } = await httpClient({ method: "get", url });
          if (error) {
            setCompareRes({});
          } else if (result?.count1.length && result?.count2.length) {
            setCompareRes(result);
          } else {
            Swal.fire({
              icon: "error",
              title: "Please change datafields to compare",
              toast: false,
              showConfirmButton: false,
              timer: 1500,
            });
          }
        } catch (error) {
          console.error("Error:", error);
        }
      } else if (field === "employmentInsights" && selectedField === 'provinceTerritory') {
        if (selectedField) filterString += `field=${selectedField}&`;
        if (selectedGeography) filterString += `geography=${JSON.parse(selectedGeography)?._id}&`;
        if (selectedGeography2) filterString += `geography2=${JSON.parse(selectedGeography2)?._id}&`;

        filterString = filterString.slice(0, -1);
        url = API_URLS.employmentComparison + filterString;

        try {
          const { result, error } = await httpClient({ method: "get", url });
          if (error) {
            setCompareRes({});
          } else if (result?.count1.length && result?.count2.length) {
            setCompareRes(result);
          } else {
            Swal.fire({
              icon: "error",
              title: "Please change datafields to compare",
              toast: false,
              showConfirmButton: false,
              timer: 1500,
            });
          }
        } catch (error) {
          console.error("Error:", error);
        }
      } else if (field === "employmentInsights" && selectedField === 'sector') {
        if (selectedField) filterString += `field=${selectedField}&`;
        if (selectedEstablishment) filterString += `establishment=${JSON.parse(selectedEstablishment)?._id}&`;
        if (selectedEstablishment2) filterString += `establishment2=${JSON.parse(selectedEstablishment2)?._id}&`;

        filterString = filterString.slice(0, -1);
        url = API_URLS.employmentComparison + filterString;

        try {
          const { result, error } = await httpClient({ method: "get", url });
          if (error) {
            setCompareRes({});
          } else if (result?.count1.length && result?.count2.length) {
            setCompareRes(result);
          } else {
            Swal.fire({
              icon: "error",
              title: "Please change datafields to compare",
              toast: false,
              showConfirmButton: false,
              timer: 1500,
            });
          }
        } catch (error) {
          console.error("Error:", error);
        }
      } else if (field === "employmentInsights" && selectedField === 'year') {
        if (selectedField) filterString += `field=${selectedField}&`;
        if (selectedSubField) filterString += `subField=${selectedSubField}&`
        if (selectedEmploymentYear) filterString += `year=${selectedEmploymentYear}&`;
        if (selectedEmploymentYear2) filterString += `year2=${selectedEmploymentYear2}&`;


        filterString = filterString.slice(0, -1);
        url = API_URLS.employmentComparison + filterString;

        try {
          const { result, error } = await httpClient({ method: "get", url });
          if (error) {
            setCompareRes({});
          } else if (result?.count1.length && result?.count2.length) {
            setCompareRes(result);
          } else {
            Swal.fire({
              icon: "error",
              title: "Please change datafields to compare",
              toast: false,
              showConfirmButton: false,
              timer: 1500,
            });
          }
        } catch (error) {
          console.error("Error:", error);
        }
      } else if (field === "employmentInsights" && selectedField === 'breakdown') {
        if (selectedField) filterString += `field=${selectedField}&`;
        if (selectedSubField) filterString += `subField=${selectedSubField}&`
        if (selectedRange) filterString += `range=${selectedRange}&`
        if (selectedRange2) filterString += `range2=${selectedRange2}&`
        if (selectedEstablishment) filterString += `establishment=${JSON.parse(selectedEstablishment)?._id}&`;
        if (selectedEstablishment2) filterString += `establishment2=${JSON.parse(selectedEstablishment2)?._id}&`;
        if (selectedGender) filterString += `gender=${selectedGender}&`;
        if (selectedGender2) filterString += `gender2=${selectedGender2}&`;


        filterString = filterString.slice(0, -1);
        url = API_URLS.employmentComparison + filterString;

        try {
          const { result, error } = await httpClient({ method: "get", url });
          if (error) {
            setCompareRes({});
          } else if (result?.count1.length && result?.count2.length) {
            setCompareRes(result);
          } else {
            Swal.fire({
              icon: "error",
              title: "Please change datafields to compare",
              toast: false,
              showConfirmButton: false,
              timer: 1500,
            });
          }
        } catch (error) {
          console.error("Error:", error);
        }
      }
    }

  };
  const getBusinessCountByGeography = (filters: string = "") => {
    showLoader(true);
    let url = API_URLS.businessCountByParentEstablishment + filters;
    httpClient({
      method: "get",
      url,
    })
      .then(({ result, error }) => {
        if (result) {
          if (result.counts?.length) {
            setEstablishmentBusinessCount(result.counts);
            const labels = result.counts.map((item) => `NAICS ${item.externalId}`);
            const titles = result.counts.map((item) => item.name);
            const values = result.counts.map((item) => item.totalCount);
            setResponse({ labels, values, titles, colors });
          }
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      })
      .finally(() => {
        showLoader(false);
      });
  };

  useEffect(() => {
    if (joyrideStatus) {
      const getPlacement = (step) => {
        if (window.innerWidth <= 768) return 'top';
        return step.target == '.comparison-filters' ? 'top' : step.placement;
      };
      const joyrideSteps = steps?.map((step,index) => {
        const contentWithVideo = (
          <div style={{ maxHeight: "300px", overflowY: "auto", display: 'flex', flexDirection: 'column' }}>
            {/* Button on the left side */}
            <button
              onClick={() =>
                setStepStates((prev) =>
                  prev.map((state, i) =>
                    i === index ? { ...state, showVideo: !state.showVideo } : state
                  )
                )
              }
              style={{ outline: 'none', alignSelf: 'flex-start', border: 'none' }}
            >
              {stepStates[index]?.showVideo ? '' : <BiSolidVideos fontSize={20} />}
            </button>
            
            {/* Video Content */}
            {stepStates[index]?.showVideo && (
              <div className="card" style={{ height: "250px", width: "330px" }}>
                <ReactPlayer
                  config={{
                    file: { attributes: { controlsList: "nodownload" } },
                  }}
                  style={{
                    borderRadius: "10px",
                    overflow: "hidden",
                    backgroundColor: "#020202",
                  }}
                  url={step?.videoUrl}
                  controls={true}
                  height="250px"
                  width="330px"
                />
              </div>
            )}
            
            {/* Content Text */}
            <br />
            <p>
              {t(step.content)
                .split("\\n")
                .map((line, idx) => (
                  <p key={idx}>{line}</p>
                ))}
            </p>
          </div>
        );
        const translatedContent = (<>
          {t(step.content)?.split('\\n')?.map((line, index, array) => (
            <p key={index}>{line}</p>
          ))}
        </>)
        // const contentWithOverflow = step.target === '.comparison-filters' ? (
        //   <div style={{ overflowY: 'auto', maxHeight: '220px' }}>
        //     {translatedContent}
        //   </div>
        // ) : (
        //   translatedContent
        // );
        return {
          ...step,
          content: step?.videoUrl ? contentWithVideo : translatedContent,
          disableCloseOnEsc: true,
          disableBeacon: true,
          showSkipButton: false,
          showNextButton: true,
          closeButtonText: "Close",
          placement: getPlacement(step)
        };
      })
      setSteps(joyrideSteps)
      setHelpRun(true)
    }
  }, [joyrideStatus,stepStates])

  useEffect(() => {
    getFilterOptions();
    // getBusinessCountByGeography()
  }, []);

  useEffect(() => {
    if (!filterOptionsLoaded) return;

    let url = API_URLS.preloadedSettings;
    let data = {
      settings: {
        ...userSetting,
        comparison: {
          componentField: field,
          compareField: selectedField,
          subField: selectedSubField,
          field1: {
            geography: JSON.parse(selectedGeography)?._id,
            year: JSON.stringify(selectedEstablishmentYear),
            period: selectedPeriod,
            employmentSize: employmentSize === "" ? "" : employmentSize == null || employmentSize === 'null' ? 'null' : JSON.parse(employmentSize)?._id,
            gender: selectedGender,
            establishment: selectedEstablishment === "" ? "" : selectedEstablishment == null || selectedEstablishment === 'null' ? 'null' : JSON.parse(selectedEstablishment)?._id,
            range: selectedRange,
            studyField: selectedStudyField == 'all' ? 'all' : selectedStudyField
          },
          field2: {
            geography: JSON.parse(selectedGeography2)?._id,
            year: JSON.stringify(selectedEstablishmentYear2),
            period: selectedPeriod2,
            employmentSize: employmentSize2 === "" ? "" : employmentSize2 == null || employmentSize2 === 'null' ? 'null' : JSON.parse(employmentSize2)?._id,
            gender: selectedGender2,
            establishment: selectedEstablishment2 === "" ? "" : selectedEstablishment2 == null || selectedEstablishment2 === 'null' ? 'null' : JSON.parse(selectedEstablishment2)?._id,
            range: selectedRange2,
            studyField: selectedStudyField2 == 'all' ? 'all' : selectedStudyField2
          }
        },
      },
    };

    httpClient({
      method: "put",
      url,
      data,
    }).then(({ result, error }) => {
      if (result) {
        let prevsettings = JSON.parse(localStorage.getItem('user') || "")
        let datatoSave = {
          ...prevsettings,
          settings: {
            ...result?.settings,
          },
        };
        localStorage.setItem("user", JSON.stringify(datatoSave));
      }
    });

  }, [filterOptionsLoaded,
    selectedEstablishmentYear,
    selectedEstablishmentYear2,
    selectedEmploymentYear,
    selectedEmploymentYear2,
    selectedPeriod,
    selectedPeriod2,
    employmentSize,
    employmentSize2,
    selectedGeography,
    selectedGeography2,
    selectedGender,
    selectedGender2,
    selectedEstablishment,
    selectedEstablishment2,
    selectedRange,
    selectedRange2,
    selectedStudyField,
    selectedStudyField2,
    field,
    selectedField,
    selectedSubField
  ]);

  return (
    <>
      <Loader show={show} />
      <HelpTour
        run={helpRun}
        steps={joyrideSteps}
        onJoyrideCallback={handleHelpTour}
      />
      <div className="flex-space-between">
        <h5 className="py-3 px-4"> {t("comparisonTitle")}</h5>
        <div className="d-flex">
          <button className="clear_btn" style={{ marginRight: "25px", fontSize: "14px" }} onClick={clearAll}>
            {t("commonSubHeadingClearAll")}
          </button>

          {/* <div className="vertical-line"></div>
          <OverlayTrigger
            delay={{ hide: 450, show: 300 }}
            overlay={(props) => (
              <Tooltip {...props} className="tooltip-justify">

                {t("comparisonHelpText").split('\\n').map((line, index, array) => (
                  <React.Fragment key={index}>
                    {line}
                    {index < array.length - 1 && (
                      <div style={{ height: '7px' }} />)}
                  </React.Fragment>
                ))}

              </Tooltip>
            )}
            placement="bottom"
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '4px',
                width: "40px",
                height: "40px",
                backgroundColor: "#f0f0f0",
                cursor: 'pointer'
              }}
            >
              <FaQuestionCircle style={{ fontSize: '20px' }} onClick={() => setHelpRun(true)} />
            </div>
          </OverlayTrigger> */}
        </div>
      </div>
      <Form className="w-100" onSubmit={handleCompareCTA}>
        <Row className="gx-0 comparison-filters">
          <Col xs={12} md={5}>
            <Card className="comparsion_form_block comparsion_form_block_right_space">
              <Card.Body>
                {field === 'educationalInsights' && (
                  <>
                    <div className="custom-form-group custom-form-group-md">
                      <label htmlFor="field" className="text-black">{t("comparisonInputTitle.field")}</label>
                      <select
                        name="field"
                        className="form-select c-form-control fw-semibold c-form-control-sm c-form-control-bg"
                        value={selectedField}
                        onChange={handleChange}
                        required
                      >
                        {educationFields.map((field) => (
                          <option key={field.value} value={field.value}>
                            {capitalize(t(field.languageKey))}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="custom-form-group custom-form-group-md">
                      <label htmlFor="studyField" className="text-black">{t("comparisonInputTitle.areaOfStudy")}</label>
                      <select
                        name="studyField"
                        className="form-select c-form-control fw-semibold c-form-control-sm c-form-control-bg"
                        value={JSON.stringify(selectedStudyField)}
                        onChange={handleChange}
                        required
                      >
                        <option value="all">
                          {t("educationalProgram.tabProvinceAndTerritory.inputValue.totalPrograms")}
                        </option>
                        {areaOfStudy.map((field) => (
                          <option key={field._id} value={JSON.stringify(field)}>
                            {capitalize(t(field.languageKey))}
                          </option>
                        ))}
                      </select>
                    </div>
                  </>
                )}
                {field === 'establishmentCount' && (
                  <>
                    <div className="custom-form-group custom-form-group-md">
                      <label htmlFor="employmentSizeSelect" className="text-black">{t('commonInputTitleEmploymentSize')}</label>
                      <select
                        name="employmentSize"
                        className="form-select c-form-control fw-semibold c-form-control-sm c-form-control-bg"
                        value={employmentSize}
                        onChange={handleChange}
                      >
                        <option value={""}>
                          {t("businessTypeTotalWithAndWithoutEmployees")}
                        </option>
                        <option value={"null"}>{t('businessTypeTotalWithoutEmployees')}</option>
                        {businessTypeList.map((businessType) => (
                          <option key={businessType._id} value={JSON.stringify(businessType)}>
                            {capitalize(t(businessType.languageKey))}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="custom-form-group custom-form-group-md">
                      <label htmlFor="GeographySelect" className="text-black">{t('commonInputTitleGeography')}</label>
                      <select
                        name="selectedGeography"
                        className="form-select c-form-control fw-semibold c-form-control-sm c-form-control-bg"
                        value={selectedGeography}
                        onChange={handleChange}
                        required
                      >
                        <option disabled>{t('commonTextSelect')}</option>
                        {geographyList.map((geography) => (
                          <option key={geography._id} value={JSON.stringify(geography)}>
                            {capitalize(t(geography.languageKey))}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="custom-form-group custom-form-group-md">
                      <label htmlFor="periodSelect" className="text-black">{t('commonInputTitlePeriod')}</label>
                      <select
                        name="selectedPeriod"
                        className="form-select c-form-control fw-semibold c-form-control-sm c-form-control-bg"
                        value={selectedPeriod}
                        onChange={handleChange}
                        required
                      >
                        <option disabled>{t('commonTextSelectPeriod')}</option>
                        {selectedEstablishmentYear && selectedEstablishmentYear?.period?.sort((a, b) => a.month < b.month).map((item, index) => (
                          <option key={index} value={item.value}>
                            {(() => {
                              switch (item.value) {
                                case 1:
                                  return t("commonPeriodInputValueJune"); // June
                                case 2:
                                  return t("commonPeriodInputValueDecember"); // December
                                // You can add more cases for other month values if needed
                                default:
                                  return t("commonPeriodInputValueUnknown"); // Fallback or unknown value
                              }
                            })()}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="custom-form-group custom-form-group-md">
                      <label htmlFor="selectedEstablishmentYear" className="text-black">{t('commonInputTitleYear')}</label>
                      <select
                        className="form-select c-form-control fw-semibold c-form-control-sm c-form-control-bg"
                        name="selectedEstablishmentYear"
                        value={JSON.stringify(selectedEstablishmentYear)}
                        onChange={handleChange}
                        required
                      >
                        <option value={""} disabled>{t('commonTextSelectYear')}</option>
                        {
                          businessCountYears
                            .map((year, index) => (
                              <option key={index} value={JSON.stringify(year)}>
                                {year.year}
                              </option>
                            ))}
                      </select>
                    </div>
                  </>
                )}
                {field === 'employmentInsights' && (
                  <>
                    <div className="custom-form-group custom-form-group-md">
                      <label htmlFor="field" className="text-black">{t("comparisonInputTitle.field")}</label>
                      <select
                        name="field"
                        className="form-select c-form-control fw-semibold c-form-control-sm c-form-control-bg"
                        value={selectedField}
                        onChange={handleChange}
                        required
                      >
                        {employmentFields.map((field) => (
                          <option key={field.value} value={field.value}>
                            {capitalize(t(field.languageKey))}
                          </option>
                        ))}
                      </select>
                    </div>
                    {selectedField === "provinceTerritory" && (
                      <div className="custom-form-group custom-form-group-md">
                        <label htmlFor="GeographySelect" className="text-black">{t('commonInputTitleGeography')}</label>
                        <select
                          name="selectedGeography"
                          className="form-select c-form-control fw-semibold c-form-control-sm c-form-control-bg"
                          value={selectedGeography}
                          onChange={handleChange}
                          required
                        >
                          <option disabled>{t('commonTextSelect')}</option>
                          {geographyList.map((geography) => (
                            <option key={geography._id} value={JSON.stringify(geography)}>
                              {capitalize(t(geography.languageKey))}
                            </option>
                          ))}
                        </select>
                      </div>
                    )}
                    {selectedField === "sector" && (
                      <div className="custom-form-group custom-form-group-md">
                        <label htmlFor="GeographySelect" className="text-black">{t("comparisonInputTitle.sector")}</label>
                        <select
                          name="establishment"
                          className="form-select c-form-control fw-semibold c-form-control-sm c-form-control-bg"
                          value={selectedEstablishment}
                          onChange={handleChange}
                          required
                        >
                          <option disabled>{t('commonTextSelect')}</option>
                          {establishmentList.map((establishment) => (
                            <option key={establishment._id} value={JSON.stringify(establishment)}>
                              {capitalize(t(establishment.languageKey))}
                            </option>
                          ))}
                        </select>
                      </div>
                    )}
                    {selectedField === "year" && (
                      <>
                        <div className="custom-form-group custom-form-group-md">
                          <label htmlFor="selectedEmploymentYear" className="text-black">{t("comparisonInputTitle.year")}</label>
                          <select
                            name="selectedEmploymentYear"
                            className="form-select c-form-control fw-semibold c-form-control-sm c-form-control-bg"
                            value={selectedEmploymentYear}
                            onChange={handleChange}
                            required
                          >
                            <option disabled>{t('commonTextSelect')}</option>
                            {employmentCountYears
                              .map((year) => (
                                <option key={year} value={year}>
                                  {year}
                                </option>
                              ))}
                          </select>
                        </div>
                        <div className="custom-form-group custom-form-group-md">
                          <label htmlFor="GeographySelect" className="text-black">{t("comparisonInputTitle.subField")}</label>
                          <select
                            name="subField"
                            className="form-select c-form-control fw-semibold c-form-control-sm c-form-control-bg"
                            value={selectedSubField}
                            onChange={handleChange}
                            required
                          >
                            <option value={""} selected>{t('commonTextSelect')}</option>
                            <option value="geography">{t("comparisonInputValue.geography")}</option>
                            <option value="establishment">{t("comparisonInputValue.establishment")}</option>
                          </select>
                        </div>
                      </>
                    )}
                    {selectedField === "breakdown" && (
                      <>
                        <div className="custom-form-group custom-form-group-md">
                          <label htmlFor="GeographySelect2" className="text-black">{t("comparisonInputTitle.subField")}</label>
                          <select
                            name="subField"
                            className="form-select c-form-control fw-semibold c-form-control-sm c-form-control-bg"
                            value={selectedSubField}
                            onChange={handleChange}
                            required
                          >
                            <option value="" selected>{t('commonTextSelect')}</option>
                            <option value="age">{t("comparisonInputValue.age")}</option>
                            <option value="ageRange">{t("comparisonInputValue.ageRange")}</option>
                            <option value="gender">{t("comparisonInputValue.gender")}</option>
                            <option value="immigrant">{t("comparisonInputValue.immigrant")}</option>
                          </select>
                        </div>
                        <div className="custom-form-group custom-form-group-md">
                          <label htmlFor="GeographySelect" className="text-black">{t("comparisonInputTitle.sector")}</label>
                          <select
                            name="establishment"
                            className="form-select c-form-control fw-semibold c-form-control-sm c-form-control-bg"
                            value={selectedEstablishment}
                            onChange={handleChange}
                            required
                          >
                            <option disabled>{t('commonTextSelect')}</option>
                            {establishmentList.map((establishment) => (
                              <option key={establishment._id} value={JSON.stringify(establishment)}>
                                {capitalize(t(establishment.languageKey))}
                              </option>
                            ))}
                          </select>
                        </div>
                        {selectedSubField === 'age' && (
                          <div className="custom-form-group custom-form-group-md">
                            <label htmlFor="sectorSelect" className="text-black">{t("comparisonInputTitle.gender")}</label>
                            <select
                              name="gender"
                              className="form-select c-form-control fw-semibold c-form-control-sm c-form-control-bg"
                              value={selectedGender}
                              onChange={handleChange}
                              required
                            >
                              <option disabled>{t('commonTextSelect')}</option>
                              <option value="men" selected>{t("comparisonInputValue.men")}</option>
                              <option value="women">{t("comparisonInputValue.women")}</option>
                            </select>
                          </div>)
                        }
                        {selectedSubField === 'ageRange' && (
                          <div className="custom-form-group custom-form-group-md">
                            <label htmlFor="ageRange" className="text-black">{t("comparisonInputTitle.range")}</label>
                            <select
                              name="ageRange"
                              className="form-select c-form-control fw-semibold c-form-control-sm c-form-control-bg"
                              value={selectedRange}
                              onChange={handleChange}
                              required
                            >
                              <option disabled>{t('commonTextSelect')}</option>
                              <option value="under30" selected>{t("comparisonInputValue.under30")}</option>
                              <option value="30to54">{t("comparisonInputValue.30to54")}</option>
                              <option value="55above">{t("comparisonInputValue.above55")}</option>
                            </select>
                          </div>)
                        }
                      </>
                    )}
                  </>
                )}
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12} md={2} className="comparision_form_block_right_space">
            <Card className="vs-card">
              <div className="vs-text">VS</div>
            </Card>
          </Col>
          <Col xs={12} md={5}>
            <Card className="comparsion_form_block comparsion_form_block_left_space">
              <Card.Body>
                {field === 'educationalInsights' && (
                  <>
                    <div className="custom-form-group custom-form-group-md">
                      <label htmlFor="field" className="text-black">{t("comparisonInputTitle.field")}</label>
                      <select
                        name="field"
                        className="form-select c-form-control fw-semibold c-form-control-sm c-form-control-bg"
                        value={selectedField}
                        onChange={handleChange}
                        required
                        disabled
                      >
                        {educationFields.map((field) => (
                          <option key={field.value} value={field.value}>
                            {capitalize(t(field.languageKey))}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="custom-form-group custom-form-group-md">
                      <label htmlFor="studyField2" className="text-black">{t("comparisonInputTitle.areaOfStudy")}</label>
                      <select
                        name="studyField2"
                        className="form-select c-form-control fw-semibold c-form-control-sm c-form-control-bg"
                        value={JSON.stringify(selectedStudyField2)}
                        onChange={handleChange}
                        required
                      >
                        <option value="all">
                          {t("educationalProgram.tabProvinceAndTerritory.inputValue.totalPrograms")}
                        </option>
                        {areaOfStudy.map((field) => (
                          <option key={field._id} value={JSON.stringify(field)}>
                            {capitalize(t(field.languageKey))}
                          </option>
                        ))}
                      </select>
                    </div>
                  </>
                )}
                {field === 'establishmentCount' && (
                  <>
                    <div className="custom-form-group custom-form-group-md">
                      <label htmlFor="employmentSizeSelect2" className="text-black">{t('commonInputTitleEmploymentSize')}</label>
                      <select
                        name="employmentSize2"
                        className="form-select c-form-control fw-semibold c-form-control-sm c-form-control-bg"
                        value={employmentSize2}
                        onChange={handleChange}
                      >
                        <option value={""} selected>
                          {t("businessTypeTotalWithAndWithoutEmployees")}
                        </option>
                        <option value={"null"}>{t('businessTypeTotalWithoutEmployees')}</option>
                        {businessTypeList.map((businessType) => (
                          <option key={businessType._id} value={JSON.stringify(businessType)}>
                            {capitalize(t(businessType.languageKey))}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="custom-form-group custom-form-group-md">
                      <label htmlFor="GeographySelect2" className="text-black">{t('commonInputTitleGeography')}</label>
                      <select
                        name="selectedGeography2"
                        className="form-select c-form-control fw-semibold c-form-control-sm c-form-control-bg"
                        value={selectedGeography2}
                        onChange={handleChange}
                        required
                      >
                        <option disabled>{t('commonTextSelect')}</option>
                        {geographyList.map((geography) => (
                          <option key={geography._id} value={JSON.stringify(geography)}>
                            {capitalize(t(geography.languageKey))}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="custom-form-group custom-form-group-md">
                      <label htmlFor="periodSelect2" className="text-black">{t('commonInputTitlePeriod')}</label>
                      <select
                        name="selectedPeriod2"
                        className="form-select c-form-control fw-semibold c-form-control-sm c-form-control-bg"
                        value={selectedPeriod2}
                        onChange={handleChange}
                        required
                      >
                        <option disabled>{t('commonTextSelectPeriod')}</option>
                        {selectedEstablishmentYear2 && selectedEstablishmentYear2?.period?.sort((a, b) => a.month < b.month).map((item, index) => (
                          <option key={index} value={item.value}>
                            {(() => {
                              switch (item.value) {
                                case 1:
                                  return t("commonPeriodInputValueJune"); // June
                                case 2:
                                  return t("commonPeriodInputValueDecember"); // December
                                // You can add more cases for other month values if needed
                                default:
                                  return t("commonPeriodInputValueUnknown"); // Fallback or unknown value
                              }
                            })()}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="custom-form-group custom-form-group-md">
                      <label htmlFor="selectedEstablishmentYear2" className="text-black">{t('commonInputTitleYear')}</label>
                      <select
                        className="form-select c-form-control fw-semibold c-form-control-sm c-form-control-bg"
                        name="selectedEstablishmentYear2"
                        value={JSON.stringify(selectedEstablishmentYear2)}
                        onChange={handleChange}
                        required
                      >
                        <option value={""} disabled>{t('commonTextSelectYear')}</option>
                        {
                          businessCountYears
                            .map((year) => (
                              <option key={year} value={JSON.stringify(year)}>
                                {year.year}
                              </option>
                            ))}
                      </select>
                    </div>
                  </>
                )}
                {field === 'employmentInsights' && (
                  <>
                    <div className="custom-form-group custom-form-group-md">
                      <label htmlFor="field" className="text-black">{t("comparisonInputTitle.field")}</label>
                      <select
                        name="field"
                        className="form-select c-form-control fw-semibold c-form-control-sm c-form-control-bg"
                        value={selectedField}
                        onChange={handleChange}
                        required
                      >
                        {employmentFields.map((field) => (
                          <option key={field.value} value={field.value} disabled>
                            {capitalize(t(field.languageKey))}
                          </option>
                        ))}
                      </select>
                    </div>
                    {selectedField === "provinceTerritory" && (
                      <div className="custom-form-group custom-form-group-md">
                        <label htmlFor="GeographySelect2" className="text-black">{t('commonInputTitleGeography')}</label>
                        <select
                          name="selectedGeography2"
                          className="form-select c-form-control fw-semibold c-form-control-sm c-form-control-bg"
                          value={selectedGeography2}
                          onChange={handleChange}
                          required
                        >
                          <option disabled>{t('commonTextSelect')}</option>
                          {geographyList.map((geography) => (
                            <option key={geography._id} value={JSON.stringify(geography)}>
                              {capitalize(t(geography.languageKey))}
                            </option>
                          ))}
                        </select>
                      </div>
                    )}
                    {selectedField === "sector" && (
                      <div className="custom-form-group custom-form-group-md">
                        <label htmlFor="sectorSelect" className="text-black">{t("comparisonInputTitle.sector")}</label>
                        <select
                          name="establishment2"
                          className="form-select c-form-control fw-semibold c-form-control-sm c-form-control-bg"
                          value={selectedEstablishment2}
                          onChange={handleChange}
                          required
                        >
                          <option disabled>{t('commonTextSelect')}</option>
                          {establishmentList.map((establishment) => (
                            <option key={establishment._id} value={JSON.stringify(establishment)}>
                              {capitalize(t(establishment.languageKey))}
                            </option>
                          ))}
                        </select>
                      </div>
                    )}
                    {selectedField === "year" && (
                      <>
                        <div className="custom-form-group custom-form-group-md">
                          <label htmlFor="selectedEmploymentYear2" className="text-black">{t("comparisonInputTitle.year")}</label>
                          <select
                            name="selectedEmploymentYear2"
                            className="form-select c-form-control fw-semibold c-form-control-sm c-form-control-bg"
                            value={selectedEmploymentYear2}
                            onChange={handleChange}
                            required
                          >
                            <option disabled>{t('commonTextSelect')}</option>
                            {employmentCountYears
                              .map((year) => (
                                <option key={year} value={year}>
                                  {year}
                                </option>
                              ))}
                          </select>
                        </div>
                        <div className="custom-form-group custom-form-group-md">
                          <label htmlFor="subField" className="text-black">{t("comparisonInputTitle.subField")}</label>
                          <select
                            name="subField"
                            className="form-select c-form-control fw-semibold c-form-control-sm c-form-control-bg"
                            value={selectedSubField}
                            onChange={handleChange}
                            required
                          >
                            <option disabled selected>{t('commonTextSelect')}</option>
                            <option value="geography" disabled >{t("comparisonInputValue.geography")}</option>
                            <option value="establishment" disabled>{t("comparisonInputValue.establishment")}</option>
                          </select>
                        </div>
                      </>
                    )}
                    {selectedField === "breakdown" && (
                      <>
                        <div className="custom-form-group custom-form-group-md">
                          <label htmlFor="subField" className="text-black">{t("comparisonInputTitle.subField")}</label>
                          <select
                            name="subField"
                            className="form-select c-form-control fw-semibold c-form-control-sm c-form-control-bg"
                            value={selectedSubField}
                            onChange={handleChange}
                            required
                          >
                            <option disabled selected value="">{t('commonTextSelect')}</option>
                            <option value="age" disabled>{t("comparisonInputValue.age")}</option>
                            <option value="ageRange" disabled>{t("comparisonInputValue.ageRange")}</option>
                            <option value="gender" disabled>{t("comparisonInputValue.gender")}</option>
                            <option value="immigrant" disabled>{t("comparisonInputValue.immigrant")}</option>
                          </select>
                        </div>
                        <div className="custom-form-group custom-form-group-md">
                          <label htmlFor="sectorSelect" className="text-black">{t("comparisonInputTitle.sector")}</label>
                          <select
                            name="establishment2"
                            className="form-select c-form-control fw-semibold c-form-control-sm c-form-control-bg"
                            value={selectedEstablishment2}
                            onChange={handleChange}
                            required
                          >
                            <option disabled>{t('commonTextSelect')}</option>
                            {establishmentList.map((establishment) => (
                              <option key={establishment._id} value={JSON.stringify(establishment)}>
                                {capitalize(t(establishment.languageKey))}
                              </option>
                            ))}
                          </select>
                        </div>
                        {selectedSubField === 'age' && (
                          <div className="custom-form-group custom-form-group-md">
                            <label htmlFor="sectorSelect" className="text-black">{t("comparisonInputTitle.gender")}</label>
                            <select
                              name="gender2"
                              className="form-select c-form-control fw-semibold c-form-control-sm c-form-control-bg"
                              value={selectedGender2}
                              onChange={handleChange}
                              required
                            >
                              <option disabled>{t('commonTextSelect')}</option>
                              <option value="men" selected>{t("comparisonInputValue.men")}</option>
                              <option value="women">{t("comparisonInputValue.women")}</option>
                            </select>
                          </div>)
                        }
                        {selectedSubField === 'ageRange' && (
                          <div className="custom-form-group custom-form-group-md">
                            <label htmlFor="ageRange2" className="text-black">{t("comparisonInputTitle.range")}</label>
                            <select
                              name="ageRange2"
                              className="form-select c-form-control fw-semibold c-form-control-sm c-form-control-bg"
                              value={selectedRange2}
                              onChange={handleChange}
                              required
                            >
                              <option disabled>{t('commonTextSelect')}</option>
                              <option value="under30" selected>{t("comparisonInputValue.under30")}</option>
                              <option value="30to54">{t("comparisonInputValue.30to54")}</option>
                              <option value="55above">{t("comparisonInputValue.above55")}</option>
                            </select>
                          </div>)
                        }
                      </>
                    )}
                  </>
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <div className="text-center comparsion_button-block">
          <button className="button button-rounded comparsion_button" type="submit">
            {t('comparisonButtonTitle')}
          </button>
        </div>
      </Form>
      {!!Object.keys(compareRes).length && (
        <CompareCharts
          componentField={field}
          compareField={selectedField}
          subField={selectedSubField}
          dataset={compareRes}
          filter1={{
            selectedStudyField,
            selectedPeriod,
            selectedGeography,
            employmentSize,
            selectedEstablishmentYear,
            selectedEmploymentYear,
            selectedEstablishment,
            selectedGender,
            selectedRange,
          }}
          filter2={{
            selectedStudyField2,
            selectedPeriod2,
            selectedGeography2,
            employmentSize2,
            selectedEstablishmentYear2,
            selectedEmploymentYear2,
            selectedEstablishment2,
            selectedGender2,
            selectedRange2
          }}
        />
      )}
    </>)
};

export default Comparison;
