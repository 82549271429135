import React, { useEffect, useState, useRef } from "react";
import Highcharts, { chart } from "highcharts";
import HighchartsReact from "highcharts-react-official";
import API_URLS from "../../constants/apiUrls.ts";
import httpClient from "../../services/httpClient.ts";
import RangeSlider from "../RangeSlider.tsx";
import { useTranslation } from "react-i18next";
import { MultiSelect } from "react-multi-select-component";
import { Loader } from "../Loader.tsx";

const LineChart = ({ tabSettings }) => {
  const { t } = useTranslation()
  const rangeSliderRef = useRef(null)
  const [showLoader, setShowLoader] = useState(false)
  const [initialDataLoading, setInitialDataLoading] = useState(true)
  const [years, setYears] = useState([])
  const [rangeValues, setRangeValues] = useState(null)
  const [colors, setColors] = useState([
    { sector: "Animal food manufacturing", color: "#de9b61" }, //  3111:
    { sector: "Grain and oilseed milling", color: "#fbb518" }, // 3112:
    { sector: "Sugar and confectionery product manufacturing", color: "#d460a2" }, // 3113:
    { sector: "Fruit and vegetable preserving and specialty food manufacturing", color: "#ef4b32" }, // 3114:
    { sector: "Dairy product manufacturing", color: "#27ade3" }, // 3115:
    { sector: "Meat product manufacturing", color: "#e91c4d" }, // 3116:
    { sector: "Seafood product preparation and packaging", color: "#113f70" }, // 3117:
    { sector: "Bakeries and tortilla manufacturing", color: "#663300" }, // 3118:
    { sector: "Other food manufacturing", color: "#119448" }, // 3119:
    { sector: "Beverage manufacturing", color: "#804795" }, // 3121:
    { sector: "Cannabis product manufacturing", color: "#0f5b2e" }, // 3123:
  ]);

  const [options, setOptions] = useState({
    chart: {
      type: 'line',
      scrollablePlotArea: {
        minWidth: 700,
        scrollPositionX: 1
      },
      animation: {
        duration: 500,     // duration of animation in milliseconds
        easing: 'easeOutBounce' // easing type for animation
      }
    },
    title: {
      text: "",
    },
    legend: {
      enabled: true, // Disable the legend
    },

    credits: {
      enabled: false,
    },
    xAxis: {
      title: "Year",
      categories: ["2021", "2022", "2023", "2024"],
    },
    yAxis: {
      title: {
        text: t("employmentInsights.tabProvinceAndTerritory.seriesName.employmentCount"),
      },
    },
    plotOptions: {
      series: {
        animation: {
          duration: 2000
        },
        marker: {
          symbol: 'circle', 
        },
      }
    },
    series: [
      {
        data: [0, 0, 0, 0],
      },
    ],
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 600, // For screens <= 600px
          },
          chartOptions: {
            chart: {
              height: 300, // Reduce chart height for smaller screens
            },
            title: {
              style: {
                fontSize: "14px", // Adjust font size for smaller screens
              },
            },
            xAxis: {
              labels: {
                style: {
                  fontSize: "10px", // Adjust font size for x-axis labels
                },
              },
            },
            yAxis: {
              labels: {
                style: {
                  fontSize: "10px", // Adjust font size for y-axis labels
                },
              },
            },
            legend: {
              itemStyle: {
                fontSize: "10px", // Adjust font size for legend items
              },
            },
          },
        },
        {
          condition: {
            maxWidth: 400, // For screens <= 400px
          },
          chartOptions: {
            chart: {
              height: 250, // Further reduce height for very small screens
            },
            xAxis: {
              labels: {
                style: {
                  fontSize: "8px", // Smaller font size for x-axis labels
                },
              },
            },
            yAxis: {
              labels: {
                style: {
                  fontSize: "8px", // Smaller font size for y-axis labels
                },
              },
            },
            legend: {
              itemStyle: {
                fontSize: "8px", // Smaller font size for legend items
              },
            },
          },
        },
      ],
    },
  });

  const [establishment, setEstablistment] = useState([]);
  const [selectedEstablishment, setSelectedEstablistment] = useState(JSON.parse(localStorage.getItem('user'))?.settings?.employment?.years?.establishment || 'all');
  const [multiEstablishments, setMultiEstablishments] = useState([])

  const handleRangeData = (values) => {
    setRangeValues([values[0], values[1]])
  }

  const clearAll = () => {
    setMultiEstablishments([])
    // setRangeValues([years?.[0], years?.[years.length - 1]])
    rangeSliderRef.current?.()
  }

  useEffect(() => {
    try {
      setShowLoader(true)
      let url = API_URLS.listEstablishments.replace(false, true);

      httpClient({
        method: "get",
        url: API_URLS.employmentDataYears,
      }).then(({ result, error }) => {
        if (result) {
          setYears(result?.years)
          setRangeValues([result?.years?.[0], result?.years?.[result.years.length - 1]])
        }
      })

      httpClient({
        method: "get",
        url,
      }).then(({ result, error }) => {
        if (result) {
          if (result.establishments) {
            const filteredEstablishment = result.establishments.filter(item => item.name !== 'Total, all industries')

            let establishments = filteredEstablishment.map(item => { return { label: t(item.languageKey), value: item._id, languageKey: item.languageKey } })
            establishments.splice(0, 0, { label: t("overallFoodIndustry"), value: "all", languageKey: "overallFoodIndustry" })
            setEstablistment([...establishments]);
            setMultiEstablishments([establishments[0]])
            setShowLoader(false)
          }
        }
      });
    } catch (error) {
      console.log(error)
    } finally {
      setInitialDataLoading(false)
    }

  }, []);

  useEffect(() => {

    setEstablistment((prev) =>
      prev.map((item) => ({
        ...item,
        label: t(item.languageKey),
      }))
    );

    setMultiEstablishments((prev) =>
      prev.map((item) => ({
        ...item,
        label: t(item.languageKey),
      }))
    );


  }, [t]);

  useEffect(() => {
    if (!initialDataLoading && multiEstablishments.length !== 0) {
      const debounceTimer = setTimeout(() => {
        try {
          let urls = {
            establishmentsDataUrl: API_URLS.employmentCountPerYear
          }

          const establishments = multiEstablishments.length > 0 ? multiEstablishments.map(item => item.value) : ''
          let dataUrl = urls.establishmentsDataUrl.replace(
            "{establishment}",
            establishments
          ).replace(
            "{yearFrom}",
            rangeValues?.[0]
          ).replace(
            "{yearTo}",
            rangeValues?.[1]
          );

          setShowLoader(true)
          httpClient({
            method: "get",
            url: dataUrl,
          }).then(({ result, error }) => {
            if (result) {
              const totalArr = [];
              const categories = [];

              if (multiEstablishments.length > 0) {

                for (let i of result.count) {
                  const dataArray = []
                  for (let d of i.yearlyCount) {
                    dataArray.push(d.totalCount)
                    categories.push(d.year);
                  }
                  if (i?.name) {
                    const color = colors.find(c => c.sector.toLowerCase() === i.name.toLowerCase())
                    totalArr.push({ name: t(i.languageKey), data: dataArray, color: color.color })
                  } else {
                    totalArr.push({ name: t("overallFoodIndustry"), data: dataArray, color: "#E61E28" })
                  }
                }

              } else {
                const dataArray = []
                for (let i of result.count) {
                  dataArray.push(i.totalCount);
                  categories.push(i.year);
                }
                totalArr.push({ name: t("overallFoodIndustry"), data: dataArray, color: "#E61E28" })
              }

              setOptions((prevState) => {
                const newData = {
                  ...prevState,
                  xAxis: { ...prevState.xAxis, categories: categories },
                  series: totalArr,
                  tooltip: {
                    enabled: true,
                    formatter: function () {
                      return "<b>" + this.x + "</b><br/>" + `${t("count")}: ` + this.y.toLocaleString('en-CA');
                    },
                  },
                  yAxis: {
                    title: {
                      text: t("employmentInsights.tabProvinceAndTerritory.seriesName.employmentCount")
                    }
                  }
                };
                return newData;
              });
            }
          });
        } catch (error) {
          console.log(error)
        } finally {
          setShowLoader(false)
        }
      }, 500); // Delay the API call by 500ms

      return () => clearTimeout(debounceTimer); // Clear the timeout if rangeValues changes before the delay ends
    }
  }, [establishment, selectedEstablishment, multiEstablishments, rangeValues, t]);

  const handleMultiEstablishmentsChange = (selected) => {

    if (selected?.length == 0) {
      setMultiEstablishments([]);
      setOptions((prevState) => {
        const newData = {
          ...prevState,
          series: [], // Ensure this correctly targets the series in your state
        };

        return newData;
      });
    } else {
      setMultiEstablishments(selected);
    }

  };
  return (
    <>
      <Loader show={showLoader} />
      <div className="row gx-0 pt-3 d-flex justify-content-between">
        {/* <div className="d-flex"> */}
          <div className="col-12 col-md-5 mb-3 mb-md-0">
            <div className="custom-form-group custom-form-group-sm ps-2">
              <label htmlFor="yearSelect" className="text-black">
                {t('employmentInsights.tabYears.inputTitle.bySector')}
              </label>
              <MultiSelect
                options={establishment}
                value={multiEstablishments}
                onChange={handleMultiEstablishmentsChange}
                hasSelectAll={false}
                labelledBy={"Select"}
              />
            </div>

          </div>
          <div className="col-12 col-md-5 mb-3 mb-md-0">
            <div className="custom-form-group custom-form-group-sm ps-2 mx-2">
              <label htmlFor="yearSelect" className="text-black">
                {t('employmentInsights.tabSectorYearly.inputTitle.dataRange')}
              </label>
              <div className="d-flex gap-1 align-items-center m">
                <RangeSlider
                  rangeData={years}
                  onchange={handleRangeData}
                  onResetRef={rangeSliderRef}
                />
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-end col-md-2">
            <button className="clear_btn" onClick={clearAll} style={{ fontSize: "15px", marginRight: "10px", marginTop:"15px" }}>
              {t("commonSubHeadingClearAll")}
            </button>
          </div>
        </div>


      {/* </div> */}
      <div className="mt-4" style={{ minHeight: '400px' }}>
        {multiEstablishments.length > 0 ? (
          <HighchartsReact highcharts={Highcharts} options={options} />
        ) : (
          <div className="text-danger d-flex justify-content-center align-items-center" style={{ minHeight: '400px' }}>
            {t('employmentInsights.tabSectorYearly.nonOptionSelectedMessage')}
          </div>
        )}
      </div>
    </>
  );
};

export default LineChart;
