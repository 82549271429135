import React, { useState, useRef, useEffect } from 'react';
import { Row, Col, Card, Button, Form } from 'react-bootstrap';
import httpClient from '../services/httpClient.ts';
import API_URLS from '../constants/apiUrls.ts';
import { ToastContainer, toast } from 'react-toastify';
import { FaDownload } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { helpStepOptions } from '../constants/commonData.js';
import { Loader } from './Loader.tsx';
import { useForm } from 'react-hook-form';
import { useUser } from '../context/UserContext.js';
import ReactPlayer from 'react-player';

const StepHelpUpload = () => {
    const { t } = useTranslation();
    const { fetchJoyrideSteps, joyrideSteps } = useUser();
    const fileInputRef = useRef(null);
    const [tabOptions, setTabOptions] = useState([])
    const [targetOptions, setTargetOptions] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    const {
        register,
        handleSubmit,
        watch,
        getValues,
        setValue,
        trigger,
        formState: { errors },
        reset,
    } = useForm();

    const selectedInsights = watch("selectedInsights");
    const selectedTab = watch("selectedTab");
    const selectedTarget = watch("selectedTarget")

    // const selectedFieldObject = helpStepOptions.find(field => field.value === selectedInsights);
    // const tabOptions = selectedFieldObject ? selectedFieldObject.tab : [];

    const onFieldChange = (fieldValue) => {
        const selectedFieldObject = helpStepOptions.find(field => field.value === fieldValue);
        setTabOptions(selectedFieldObject ? selectedFieldObject.tab : []);

        // Clear selectedTab and selectedTarget when selectedInsights changes
        setValue("selectedInsights", fieldValue); // Update the selected field
        setValue("selectedTab", ""); // Clear selected tab if no tabs
        setValue("selectedTarget", ""); // Clear selected target if no tabs
    };

    const onTabChange = (tabValue) => {
        const selectedTabObject = tabOptions?.find(tab => tab.value === tabValue);
        const targetOptions = selectedTabObject ? selectedTabObject.target : [];
        setTargetOptions(targetOptions)
        setValue("selectedTarget", "");
    };


    // const selectedTabObject = tabOptions.find(tab => tab.value === selectedTab);
    // const targetOptions = selectedTabObject ? selectedTabObject.target : [];

    let hasVideoUrl
    if (selectedInsights === 'comparison') {
        const stepArray = joyrideSteps?.[`${selectedInsights}`]
        const step = stepArray?.find(step => step.target === `.${selectedTarget}`)
        hasVideoUrl = step?.videoUrl
    } else if (selectedInsights === "establishmentInsights" && selectedTab === 'tabSector') {
        const stepArray = joyrideSteps?.[`${selectedInsights}`]?.[`${selectedTab}`]
        const splitValues = selectedTarget?.split("_");

        if (splitValues[1] === 'pie' && stepArray?.pieChartSteps) {
            const step = stepArray?.pieChartSteps?.find(step => step.target === `.${splitValues[0]}`)
            hasVideoUrl = step?.videoUrl
        } else {
            const step = stepArray?.parentSteps?.find(step => step.target === `.${selectedTarget}`)
            hasVideoUrl = step?.videoUrl
        }
    } else if (selectedTarget === 'pagination-filter-dropdown' && selectedInsights === 'educationalProgramInsights') {

        const stepArray = joyrideSteps?.[`${selectedInsights}`]?.[`${'tabProvinceTerritory'}`]
        const step = stepArray?.find(step => step.target === `.${selectedTarget}`)
        hasVideoUrl = step?.videoUrl
    } else if (selectedTarget === 'url-column' && selectedInsights === 'educationalProgramInsights') {
        const stepArray = joyrideSteps?.[`${selectedInsights}`]?.[`${'tabProvinceTerritory'}`]
        const step = stepArray?.find(step => step.target === `.${selectedTarget}`)
        hasVideoUrl = step?.videoUrl
    } else {
        const stepArray = joyrideSteps?.[`${selectedInsights}`]?.[`${selectedTab}`]
        const step = stepArray?.find(step => step.target === `.${selectedTarget}`)
        hasVideoUrl = step?.videoUrl
    }

    const uploadFile = (data) => {
        try {
            setIsLoading(true)
            const url = API_URLS.uploadStepHelpVideo;
            let formData = new FormData();
            formData.append("file", data.file[0]);
            formData.append('insights', data.selectedInsights)
            formData.append('tab', data.selectedTab)
            formData.append('target', data.selectedTarget)

            httpClient({
                method: "post",
                url,
                data: formData,
                headers: { "Content-Type": "multipart/form-data" },
            }).then(({ result, error }) => {
                if (result) {
                    fetchJoyrideSteps()
                    reset()
                    setIsLoading(false)
                    toast.success('Video uploaded successfully');
                }

                if (error) {
                    setIsLoading(false)
                    toast.error(error.error || error.message);
                }
            });
        } catch (error) {
            console.log(error)
        }
    };

    const deleteVideo = async () => {
        try {
            // Trigger validation for all fields
            const isValid = await trigger();  // This will validate all the fields

            if (!isValid) {
                // If validation fails, you can show a message or handle it
                toast.error("Fields not valid");
                return;  // Stop the function if validation fails
            }
            setIsLoading(true)
            const url = API_URLS.deleteStepHelpVideo;
            const formData = getValues();
            const bodyData = {
                insights: formData.selectedInsights,
                tab: formData.selectedTab,
                target: formData.selectedTarget
            }

            httpClient({
                method: "post",
                url,
                data: bodyData
                // headers: { "Content-Type": "multipart/form-data" },
            }).then(({ result, error }) => {
                if (result) {
                    fetchJoyrideSteps()
                    reset()
                    setIsLoading(false)
                    toast.success('Video deleted successfully');
                }

                if (error) {
                    setIsLoading(false)
                    toast.error('Video not deleted');
                }
            });
        } catch (error) {
            console.log(error)
        }
    };

    return (
        <>
            <ToastContainer />
            <Loader show={isLoading} />
            <div style={{ width: '100%', marginTop: "10px" }}>
                
                <form>
                    <div className="row g-3 mx-3">
                        {/* Select Field */}
                        <div className="col-md-4 col-12">
                            <div className="custom-form-group custom-form-group-md">
                                <label htmlFor="field" className="text-black">{t("settings.tabVideoUpload.selectField.inputTitle")}</label>
                                <select
                                    {...register("selectedInsights", { required: "Field selection is required" })}
                                    onChange={(e) => onFieldChange(e.target.value)}
                                    className="form-select c-form-control fw-semibold c-form-control-sm c-form-control-bg"
                                >
                                    <option value="">{t("settings.tabVideoUpload.selectField.inputTitle")}</option>
                                    {helpStepOptions.map((field) => (
                                        <option key={field.value} value={field.value}>
                                            {t(field.translationKey)}
                                        </option>
                                    ))}
                                </select>
                                {errors.selectedInsights && <span className="text-danger">{errors.selectedInsights.message}</span>}
                            </div>
                        </div>

                        {/* Select Tab */}
                        <div className="col-md-4 col-12">
                            <div className="custom-form-group custom-form-group-md">
                                <label htmlFor="tab" className="text-black">{t("settings.tabVideoUpload.selectTab.inputTitle")}</label>
                                <select
                                    {...register("selectedTab", { required: "Tab selection is required" })}
                                    onChange={(e) => onTabChange(e.target.value)}
                                    className="form-select c-form-control fw-semibold c-form-control-sm c-form-control-bg"
                                >
                                    <option value="">{t("settings.tabVideoUpload.selectTab.inputTitle")}</option>
                                    {tabOptions?.map((field) => (
                                        <option key={field.value} value={field.value}>
                                            {t(field.translationKey)}
                                        </option>
                                    ))}
                                </select>
                                {errors.selectedTab && <span className="text-danger">{errors.selectedTab.message}</span>}
                            </div>
                        </div>

                        {/* Select Step */}
                        <div className="col-md-4 col-12">
                            <div className="custom-form-group custom-form-group-md">
                                <label htmlFor="step" className="text-black">{t("settings.tabVideoUpload.selectStep.inputTitle")}</label>
                                <select
                                    {...register("selectedTarget", { required: "Step selection is required" })}
                                    className="form-select c-form-control fw-semibold c-form-control-sm c-form-control-bg"
                                >
                                    <option value="">{t("settings.tabVideoUpload.selectStep.inputTitle")}</option>
                                    {targetOptions.map((field) => (
                                        <option key={field.value} value={field.value}>
                                            {t(field.translationKey)}
                                        </option>
                                    ))}
                                </select>
                                {errors.selectedTarget && <span className="text-danger">{errors.selectedTarget.message}</span>}
                            </div>
                        </div>
                        {
                            selectedInsights && selectedTab && selectedTarget ? (
                                hasVideoUrl ? (
                                    // If a video URL is available, display the video player and "Remove" button
                                    // <div className="col-md-8 col-12 d-flex flex-column flex-md-row align-items-center gap-2">

                                    //         <ReactPlayer
                                    //             config={{ file: { attributes: { controlsList: 'nodownload' } } }}
                                    //             style={{
                                    //                 borderRadius: '10px',
                                    //                 overflow: 'hidden',
                                    //                 backgroundColor: "#020202",
                                    //                 height: "250px !important",
                                    //                 width: "100% !important"
                                    //             }}
                                    //             url={hasVideoUrl}
                                    //             controls={true}
                                    //             className="card w-100 h-100"
                                    //             // width="100%"  // Ensure ReactPlayer takes full width of the container
                                    //             // height="auto" // Maintain aspect ratio on smaller screens
                                    //         />

                                    //     <div className="w-100 w-md-25 d-flex justify-content-center">
                                    //         <button
                                    //             className="btn btn-danger"
                                    //             type="button"
                                    //             onClick={deleteVideo}>
                                    //             {t('remove')}
                                    //         </button>
                                    //     </div>
                                    // </div>
                                    <div className="col-md-8 col-12 d-flex flex-column gap-2">
                                        {/* ReactPlayer */}
                                        <div className="w-xs-100 w-sm-75 w-100" style={{
                                            maxWidth: '100%',
                                            height: "300px"
                                        }}>
                                            <ReactPlayer
                                                config={{ file: { attributes: { controlsList: 'nodownload', style: { objectFit: 'cover', width: '100%', height: "100%" } } } }}
                                                style={{
                                                    borderRadius: '10px',
                                                    overflow: 'hidden',
                                                    backgroundColor: "#020202",
                                                }}
                                                url={hasVideoUrl}
                                                controls={true}
                                                className="w-100"
                                                height="300px"
                                            />
                                        </div>

                                        {/* Button */}
                                        <div className="w-xs-100 w-sm-25 w-md-25 d-flex justify-content-end align-items-center">
                                            <button
                                                className="btn btn-danger"
                                                type="button"
                                                onClick={deleteVideo}
                                            >
                                                {t('remove')}
                                            </button>
                                        </div>
                                    </div>

                                ) : (
                                    // If no video URL is available, display the file upload form
                                    <div className="col-md-8 col-12">
                                        <div className="custom-form-group custom-form-group-md">
                                            <label htmlFor="file" className="text-black">{"Select File"}</label>
                                            <Form.Control
                                                type="file"
                                                {...register("file", {
                                                    required: "File selection is required",
                                                    validate: {
                                                        fileSize: (value) => {
                                                            // Validate file size is <= 5 MB
                                                            if (value[0]?.size <= 5 * 1024 * 1024) {
                                                                return true;
                                                            }
                                                            return "File size should not exceed 5 MB";
                                                        },
                                                        fileExtension: (value) => {
                                                            const allowedExtensions = ["mp4", "webm", "mpeg", "mkv"];
                                                            const fileName = value[0]?.name || "";
                                                            const fileExtension = fileName.split(".").pop().toLowerCase();
                                                            if (allowedExtensions.includes(fileExtension)) {
                                                                return true;
                                                            }
                                                            return `Only ${allowedExtensions.join(", ")} files are allowed`;
                                                        },
                                                    },
                                                })}
                                                className="form-control"
                                            />
                                            {errors.file && <span className="text-danger">{errors.file.message}</span>}
                                        </div>
                                    </div>
                                )
                            ) : null // Render nothing when the required values are not selected
                        }

                    </div>

                    {!hasVideoUrl && (
                        <div className="row mt-3">
                            <div className="col-12 d-flex justify-content-end gap-2">
                                <Button variant="secondary" onClick={() => reset()}>
                                    {t('translationButtonTitleReset')}
                                </Button>
                                <Button variant="primary" onClick={handleSubmit(uploadFile)}>
                                    {t('translationButtonTitleUploadFile')}
                                </Button>
                            </div>
                        </div>
                    )}
                </form>
            </div>
        </>
    );
};

export default StepHelpUpload;
