import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';

interface CustomModalProps {
//   title: string;
image: string
//   videoUrl: string;
  show: boolean;
  onClose: () => void;
  showVideo: () => void;
  runHelp: () => void;
//   onConfirm?: () => void;
}

const WelcomeHelpModal: React.FC<CustomModalProps> = ({
    image,
//   videoUrl,
  show,
  onClose,
  showVideo,
  runHelp,
//   onConfirm,
}) => {
    
  return (
    <Modal
        show={show}
        onHide={onClose}
        backdrop="static"
        keyboard={false}
        size="md"
        centered
      // style={style}
      >
        {/* <Modal.Header >
          <Modal.Title>Help Videos</Modal.Title>
        </Modal.Header> */}
        <Modal.Body>
          <div>
            <img src={image} alt="" style={{ width: "100%", height: "300px" }} />
            <h5 style={{ textAlign: "center" }}>Welcome to our guide</h5>
            <p style={{ textAlign: "center" }}>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Illum at unde ex itaque optio quasi possimus ratione tenetur quas deserunt eveniet ipsum, dolorum vero, quae, nihil doloribus labore repudiandae. Obcaecati.</p>
            <div className="d-flex justify-content-end gap-1">
              <button className="button" onClick={() => {showVideo();onClose()}}>Watch our dashboard help video</button>
              <button className="button" onClick={() => {runHelp();onClose()}}>Go to our guided help tour</button>
            </div>
          </div>
          {/* <div className="d-flex gap-2 justify-content-around">
            <div className="card" style={{ width: '18rem' }}>
              <img className="card-img-top" src={dummyImage} alt="Card image cap" />
              <div className="card-body">
                <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
              </div>
            </div>
            <div className="card">
              <ReactPlayer
                light={<img src={dummyImage} alt='Thumbnail' style={{width: '100%', height: '100%', objectFit: 'cover'}}/>}
                url={dummyVideo}
                controls={true} 
                playing={playing}
                onClickPreview={() => setPlaying(true)}
                />
            </div>
          </div> */}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => onClose()}>
            Cancel
          </Button>
          {/* <Button variant="primary" onClick={confirmEventTriggered}>Confirm</Button> */}
        </Modal.Footer>
      </Modal>
  );
};

export default WelcomeHelpModal;
