import React, { useEffect, useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { IconSetName } from "../constants/commonData";
import { useTranslation } from "react-i18next";
import HighchartsPatternFill from "highcharts/modules/pattern-fill";
import { capitalizeString } from "../services/commonFn";
import { FaInfoCircle, FaQuestionCircle } from "react-icons/fa";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import i18n from "i18next";
import ReactDOM from "react-dom";

const CompareCharts = ({ componentField, compareField, subField, dataset, filter1, filter2 }) => {

  const { t } = useTranslation();
  HighchartsPatternFill(Highcharts);

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [options, setOptions] = useState({})
  const [colors, setColors] = useState([
    "#de9b61", //  3111:
    "#fbb518", // 3112:
    "#d460a2", // 3113:
    "#ef4b32", // 3114:
    "#27ade3", // 3115:
    "#e91c4d", // 3116:
    "#113f70", // 3117:
    "#663300", // 3118:
    "#119448", // 3119:
    "#804795", // 3121:
    "#0f5b2e", // 3123:
  ]);

  const formatTypeName = (str) => {
    const words = str.split(" ");

    const capitalizedWords = words.map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    });
    return capitalizedWords.join(" ");
  };

  const getPeriodName = (period: string): string => {
    switch (Number(period)) {
      case 1:
        return t("commonPeriodInputValueJune");
      case 2:
        return t("commonPeriodInputValueDecember");
      default:
        return ''; // Handle unexpected values
    }
  };

  // useEffect(() => {
  //   const handleResize = () => {
  //     if (window.innerWidth <= 768) {
  //       Highcharts.setOptions((prevOptions) => ({
  //         ...prevOptions,
  //         series: prevOptions.series?.map((series) => ({
  //           ...series,
  //           pointWidth: 15,
  //         })),
  //       }));
  //     } else if (window.innerWidth > 768 && window.innerWidth <= 1024) {
  //       Highcharts.setOptions((prevOptions) => ({
  //         ...prevOptions,
  //         series: prevOptions.series?.map((series) => ({
  //           ...series,
  //           pointWidth: undefined, // Reset pointWidth for non-mobile view
  //         })),
  //       }));
  //     }
  //   };

  //   window.addEventListener("resize", handleResize);
  //   handleResize(); // Initial call to set options based on the initial screen size

  //   return () => window.removeEventListener("resize", handleResize);
  // }, [window.innerWidth]);

  useEffect(() => {

    // Define the chart configuration
    let categoriesData = [];
    let filter1Data;
    let filter2Data;
    let filter1Title;
    let filter2Title;
    let seriesData;
    // let chartTitle;
    let chartTitle
    let chartType
    let chartHeight
    if (componentField == 'establishmentCount') {
      chartTitle = t("comparisonYAxisTitle.establishmentCount")
      chartHeight = 600
      chartType = 'column'
      filter1Title =
        Object.keys(filter1).length &&
        `<p>${t("comparisonSeriesTitle.geography")}: ${formatTypeName(
          capitalizeString(t(JSON.parse(filter1?.selectedGeography)["languageKey"]))
        )}</p><br/><p>${t("comparisonSeriesTitle.employmentSize")}: ${filter1.employmentSize === "" ? capitalizeString(t("businessTypeTotalWithAndWithoutEmployees")) : filter1.employmentSize === "null"
          ? capitalizeString(t('businessTypeTotalWithoutEmployees'))
          : capitalizeString(t(JSON.parse(filter1.employmentSize)["languageKey"]))
        }</p><br/><p>${t("comparisonSeriesTitle.period")}: ${getPeriodName(filter1?.selectedPeriod)}</p><br/><p>${t("comparisonSeriesTitle.year")}: ${filter1.selectedEstablishmentYear.year
        }</p><br/>`;
      filter2Title =
        Object.keys(filter2).length &&
        `<p>${t("comparisonSeriesTitle.geography")}: ${formatTypeName(
          capitalizeString(t(JSON.parse(filter2.selectedGeography2)["languageKey"]))
        )}</p><br/><p>${t("comparisonSeriesTitle.employmentSize")}: ${filter2.employmentSize2 === "" ? capitalizeString(t("businessTypeTotalWithAndWithoutEmployees")) : filter2.employmentSize2 === "null"
          ? capitalizeString(t('businessTypeTotalWithoutEmployees'))
          : capitalizeString(t(JSON.parse(filter2.employmentSize2)["languageKey"]))
        }</p><br/><p>${t("comparisonSeriesTitle.period")}: ${getPeriodName(filter2?.selectedPeriod2)}</p><br/><p>${t("comparisonSeriesTitle.year")}: ${filter2.selectedEstablishmentYear2.year
        }</p><br/>`;
      if (dataset && Object.keys(dataset).length) {

        categoriesData = dataset.count1.map((d, index) => ({ text: "NAICS " + d.externalId, tooltip: d.name, sc: d.externalId }));

        filter1Data = dataset.count1.map((d, index) => ({
          sector: formatTypeName(t(d.languageKey)),
          y: d.totalCount,
          color: colors[index]
        }));
        filter2Data = dataset.count2.map((d, index) => ({
          sector: formatTypeName(t(d.languageKey)),
          y: d.totalCount,
          color: {
            pattern: {
              path: {
                d: "M 0 0 L 10 10 M 9 -1 L 11 1 M -1 9 L 1 11",
                strokeWidth: 4,
                stroke: colors[index],
              },
              width: 10,
              height: 10,
              opacity: 0.7,
            },
          },
        }));

      }
    } else {
      if (componentField === "educationalInsights" && compareField === 'provinceTerritory') {
        chartTitle = t('comparisonYAxisTitle.programCount')
        chartHeight = 600
        chartType = 'column'
        categoriesData = dataset.count1.map(item => capitalizeString(t(item.province)))
        filter1Data = [...dataset.count1]
        filter2Data = [...dataset.count2]
        filter1Title = Object.keys(filter1).length &&
          `<p>${t("comparisonSeriesTitle.studyField")}: ${filter1?.selectedStudyField === "all" ? `${t("comparisonSeriesTitle.totalPrograms")}` : capitalizeString(t(filter1?.selectedStudyField?.languageKey))}
        </p>`;
        filter2Title = Object.keys(filter2).length &&
          `<p>${t("comparisonSeriesTitle.studyField")}: ${filter2?.selectedStudyField2 === "all" ? `${t("comparisonSeriesTitle.totalPrograms")}` : capitalizeString(t(filter2?.selectedStudyField2?.languageKey))}
        </p>`;
      } else if (componentField === "educationalInsights" && compareField === 'levelInstruction') {
        chartTitle = t('comparisonYAxisTitle.programCount')
        chartHeight = 600
        chartType = 'column'
        categoriesData = dataset.count1.map(item => capitalizeString(t(item.levelOfInstruction)))
        filter1Data = [...dataset.count1]
        filter2Data = [...dataset.count2]
        filter1Title = Object.keys(filter1).length &&
          `<p>${t("comparisonSeriesTitle.studyField")}: ${filter1?.selectedStudyField === "all" ? `${t("comparisonSeriesTitle.totalPrograms")}` : capitalizeString(t(filter1?.selectedStudyField?.languageKey))}
        </p>`;
        filter2Title = Object.keys(filter2).length &&
          `<p>${t("comparisonSeriesTitle.studyField")}: ${filter2?.selectedStudyField2 === "all" ? `${t("comparisonSeriesTitle.totalPrograms")}` : capitalizeString(t(filter2?.selectedStudyField2?.languageKey))}
        </p>`;
      } else if (componentField === "educationalInsights" && compareField === 'apprenticeOffered') {
        chartTitle = t('comparisonYAxisTitle.programCount')
        chartHeight = 600
        chartType = 'column'
        categoriesData = dataset.count1.map(item => capitalizeString(t(item.province)))
        filter1Data = [...dataset.count1]
        filter2Data = [...dataset.count2]
        filter1Title = Object.keys(filter1).length &&
          `<p>${t("comparisonSeriesTitle.studyField")}: ${filter1?.selectedStudyField === "all" ? `${t("comparisonSeriesTitle.totalPrograms")}` : capitalizeString(t(filter1?.selectedStudyField?.languageKey))}
        </p>`;
        filter2Title = Object.keys(filter2).length &&
          `<p>${t("comparisonSeriesTitle.studyField")}: ${filter2?.selectedStudyField2 === "all" ? `${t("comparisonSeriesTitle.totalPrograms")}` : capitalizeString(t(filter2?.selectedStudyField2?.languageKey))}
        </p>`;
      } else if (componentField === "employmentInsights" && compareField === 'provinceTerritory') {
        chartTitle = t('comparisonYAxisTitle.employmentCount')
        chartHeight = 600
        chartType = 'column'
        categoriesData = dataset.count1.map(item => item.year.toString())
        filter1Data = [...dataset.count1]
        filter2Data = [...dataset.count2]
        filter1Title = Object.keys(filter1).length &&
          `<p>${t("comparisonSeriesTitle.geography")}: ${capitalizeString(t(JSON.parse(filter1?.selectedGeography).languageKey))}
        </p>`;
        filter2Title = Object.keys(filter2).length &&
          `<p>${t("comparisonSeriesTitle.geography")}: ${capitalizeString(t(JSON.parse(filter2?.selectedGeography2).languageKey))}
        </p>`;
      }
      else if (componentField === "employmentInsights" && compareField === 'sector') {
        chartTitle = t("comparisonYAxisTitle.employmentCount")
        chartHeight = 600
        chartType = 'column'
        categoriesData = dataset.count1.map(item => item.year.toString())
        filter1Data = [...dataset.count1]
        filter2Data = [...dataset.count2]
        filter1Title = Object.keys(filter1).length &&
          `<p>${t("comparisonSeriesTitle.sector")}: ${capitalizeString(t(JSON.parse(filter1?.selectedEstablishment).languageKey))}
        </p>`;
        filter2Title = Object.keys(filter2).length &&
          `<p>${t("comparisonSeriesTitle.sector")}: ${capitalizeString(t(JSON.parse(filter2?.selectedEstablishment2).languageKey))}
        </p>`;
      } else if (componentField === "employmentInsights" && compareField === 'year') {
        if (subField === "geography") {
          chartTitle = t("comparisonYAxisTitle.employmentCount")
          chartHeight = 600
          chartType = 'column'
          categoriesData = dataset.count1.map(item => capitalizeString(t(item.province)))
          filter1Data = [...dataset.count1]
          filter2Data = [...dataset.count2]
          filter1Title = Object.keys(filter1).length &&
            `<p>${t("comparisonSeriesTitle.year")}: ${filter1?.selectedEmploymentYear}
          </p>`;
          filter2Title = Object.keys(filter2).length &&
            `<p>${t("comparisonSeriesTitle.year")}: ${filter2?.selectedEmploymentYear2}
          </p>`;
        } else if (subField === "establishment") {
          chartTitle = t("comparisonYAxisTitle.employmentCount")
          chartHeight = 600
          chartType = 'column'
          categoriesData = dataset.count1.map(item => capitalizeString(t(item.establishment)))
          // categoriesData = dataset.count1.map((d, index) => ({ text: "NAICS " + d.externalId, tooltip: d.name }));
          filter1Data = [...dataset.count1]
          filter2Data = [...dataset.count2]
          filter1Title = Object.keys(filter1).length &&
            `<p>${t("comparisonSeriesTitle.year")}: ${filter1?.selectedEmploymentYear}
          </p>`;
          filter2Title = Object.keys(filter2).length &&
            `<p>${t("comparisonSeriesTitle.year")}: ${filter2?.selectedEmploymentYear2}
          </p>`;
        }

      } else if (componentField === "employmentInsights" && compareField === 'breakdown') {
        if (subField === "age") {
          chartType = "bar"
          chartHeight = 400
          const categoryData = dataset.count1.map(item => item.range)
          const data1 = dataset.count1.map(item => { return { y: -Number(item.percentage.toFixed(1)), value: item.y } })
          const data2 = dataset.count2.map(item => { return { y: Number(item.percentage.toFixed(1)), value: item.y } })
          const series1Title = `<p>${t("comparisonSeriesTitle.establishment")}: ${capitalizeString(t(JSON.parse(filter1.selectedEstablishment).languageKey))}<br/>${t("comparisonSeriesTitle.gender")}: ${capitalizeString(t(filter1.selectedGender))} </p>`
          const series2Title = `<p>${t("comparisonSeriesTitle.establishment")}: ${capitalizeString(t(JSON.parse(filter2.selectedEstablishment2).languageKey))}<br/>${t("comparisonSeriesTitle.gender")}: ${capitalizeString(t(filter2.selectedGender2))} </p>`
          seriesData = [{ name: series1Title, data: data1, color: "#fbb518" }, { name: series2Title, data: data2, color: "#ef4b32" }]
          categoriesData = categoryData
        } else if (subField === "ageRange") {
          chartType = "bar"
          chartHeight = 200
          const categoryData = dataset.count1.map(item => capitalizeString(t(item.gender)))
          const data1 = dataset.count1.map(item => { return { y: -Number(item.percentage.toFixed(1)), value: item.count } })
          const data2 = dataset.count2.map(item => { return { y: Number(item.percentage.toFixed(1)), value: item.count } })
          seriesData = [{ name: capitalizeString(t(JSON.parse(filter1.selectedEstablishment).languageKey)), data: data1, color: "#fbb518" }, { name: capitalizeString(t(JSON.parse(filter2.selectedEstablishment2).languageKey)), data: data2, color: "#ef4b32" }]
          categoriesData = categoryData
        } else if (subField === "gender") {
          chartType = "bar"
          chartHeight = 200
          const categoryData = dataset.count1.map(item => capitalizeString(t(item.gender)))
          const data1 = dataset.count1.map(item => { return { y: -Number(item.percentage.toFixed(1)), value: item.count } })
          const data2 = dataset.count2.map(item => { return { y: Number(item.percentage.toFixed(1)), value: item.count } })
          seriesData = [{ name: capitalizeString(t(JSON.parse(filter1.selectedEstablishment).languageKey)), data: data1, color: "#fbb518" }, { name: capitalizeString(t(JSON.parse(filter2.selectedEstablishment2).languageKey)), data: data2, color: "#ef4b32" }]
          categoriesData = categoryData
        } else if (subField === "immigrant") {
          chartType = "bar"
          chartHeight = 200
          const categoryData = ["Immigrants", "Non Immigrants", "Non Permanent Resident"].map(item => t(item))
          // const data1 = dataset.count1.map(item => { return { y: Number(item.percentage.toFixed(2)), value: item.y } })
          const data1 = dataset.count1.map(item => { return [{ y: -Number(item.immigrantPercentage.toFixed(1)), value: item.immigrants }, { y: -Number(item.nonImmigrantsPercentage.toFixed(2)), value: item.nonImmigrants }, { y: -Number(item.nonPermanentResidentPercentage.toFixed(2)), value: item.nonPermanentResidents }] })
          const data2 = dataset.count2.map(item => { return [{ y: Number(item.immigrantPercentage.toFixed(1)), value: item.immigrants }, { y: Number(item.nonImmigrantsPercentage.toFixed(2)), value: item.nonImmigrants }, { y: Number(item.nonPermanentResidentPercentage.toFixed(2)), value: item.nonPermanentResidents }] })
          seriesData = [{ name: capitalizeString(t(JSON.parse(filter1.selectedEstablishment).languageKey)), data: data1[0], color: "#fbb518" }, { name: capitalizeString(t(JSON.parse(filter2.selectedEstablishment2).languageKey)), data: data2[0], color: "#ef4b32" }]
          categoriesData = categoryData
        }
      }
    }

    const options = {
      chart: {
        type: chartType,
        height: chartHeight,
        scrollablePlotArea: {
          minWidth: 700,
          scrollPositionX: 1
        },
        events: {
          load: function () {
            const chart = this;
            const iconContainerId = 'icon-container-comparison';
            const tooltipId = 'icon-tooltip-comparison';

            const addIconWithTooltip = () => {
              // Remove the existing icon and tooltip if they exist
              let existingIconContainer = document.getElementById(iconContainerId);
              if (existingIconContainer) existingIconContainer.remove();

              let existingTooltip = document.getElementById(tooltipId);
              if (existingTooltip) existingTooltip.remove();

              // Create a div for the icon
              let iconDiv = document.createElement('div');
              iconDiv.id = iconContainerId;
              iconDiv.className = 'custom-icon';

              // Ensure the chart container exists before appending elements
              if (chart.container) {
                // Set chart container position to relative
                chart.container.style.position = 'relative';
                chart.container.appendChild(iconDiv);
                // chart.container.appendChild(tooltipDiv);
              }

              // Render the React icon inside the div
              try {
                ReactDOM.render(
                  <CustomIconWithTooltip
                    iconContainerId={iconContainerId}
                    tooltipId={tooltipId}
                  />,
                  iconDiv
                );
              } catch (error) {
                console.error("Error rendering the icon:", error);
              }

              // Set the icon's position in the top-right corner
              const updateIconPosition = () => {
                if (chart.container) {
                  iconDiv.style.right = '25px';
                  iconDiv.style.top = '10px';
                }
              };

              updateIconPosition();
              Highcharts.addEvent(chart, 'redraw', updateIconPosition);
              window.addEventListener('resize', updateIconPosition);
            };
            addIconWithTooltip();
          }
        }
      },
      credits: {
        enabled: false,
      },
      title: {
        text: t("comparisonChartHeading"),
        align: "left",
      },
      xAxis: componentField === 'employmentInsights' && compareField === 'breakdown' && (subField === 'age' || subField === 'ageRange' || subField === 'gender' || subField === 'immigrant') ? [
        {
          categories: categoriesData,
        },
        {
          opposite: true,
          reversed: false,
          categories: categoriesData,
          linkedTo: 0,
          // min: 0,
          // max: 4,
          // scrollbar: {
          //   enabled: true
          // },
        },
      ] : {
        categories: categoriesData,
        labels: {
          formatter: function () {
            if (componentField == 'establishmentCount') {
              let iconName = this.value.text.replace(/ /g, "");
              let icons = `<img src="${IconSetName[iconName]}" alt="SI" height=${window.innerWidth <= 768 ? "20px" : "34px"} width=${window.innerWidth <= 768 ? "20px" : "34px"} />`;
              return icons;
            } else {
              return this.value
            }
          },
          useHTML: true, // Enable HTML for label
        },
        crosshair: true,
      },
      yAxis: componentField === 'employmentInsights' && compareField === 'breakdown' && (subField === 'age' || subField === 'ageRange' || subField === 'gender' || subField === 'immigrant') ? {
        title: {
          text: "",
        },
        labels: {
          formatter: function () {
            return Math.abs(this.value) + "%";
          },
        },
      } : {
        min: 0,
        title: {
          text: chartTitle,
        },
      },
      tooltip: {
        formatter: function () {
          if (componentField == 'establishmentCount') {
            return (
              (
                "<b>" +
                this.point.sector +
                "</b><br/><b>" + `${t("naics")} ` +
                this.x.sc +
                "</b><br/>" +
                "<b>" +
                this.series.name +
                "</b><br/>" +
                `<b>${t("establishments")}: ` +
                this.y.toLocaleString('en-CA') +
                "</b><br/>"
              )
            )
          } else if (componentField === "educationalInsights") {
            return (
              "<b>" +
              this.series.name +
              "</b><br/>" +
              `<b>${t("comparisonSeriesTitle.programs")}: ` +
              this.y.toLocaleString('en-CA') +
              "</b><br/>"
            )
          } else if (componentField == 'employmentInsights' && compareField == 'year' && subField == "establishment") {

            return (
              "<b>" +
              `${t(this.point.establishment)}` +
              "</b><br/>" +
              "<b>" +
              this.series.name +
              "</b><br/>" +
              `<b>${t("comparisonSeriesTitle.count")}: ` +
              this.y.toLocaleString('en-CA') +
              "</b><br/>"
            )
          } else if (componentField === 'employmentInsights' && compareField === 'breakdown' && subField === 'age') {

            const language = i18n.language
            let value = Math.abs(this.y)
            let percent
            if (language == "en") {
              percent = value
            } else if (language == "fr") {
              percent = value.toString().replace(".", ",")
            }
            return (
              "<b>" +
              this.series.name +
              "<br/>" + `${t("comparisonSeriesTitle.range")}: ` +
              this.x +
              "</b><br/>" +
              `${t("comparisonSeriesTitle.percent")}: ` +
              percent +
              "%"
            );

          } else if (componentField === 'employmentInsights' && compareField === 'breakdown' && subField === 'ageRange') {
            const language = i18n.language
            let value = Math.abs(this.y)
            let percent
            if (language == "en") {
              percent = value
            } else if (language == "fr") {
              percent = value.toString().replace(".", ",")
            }
            return (
              "<b>" +
              this.series.name +
              "<br/>" + `${t("comparisonSeriesTitle.gender")}: ` +
              this.x +
              "</b><br/>" +
              `${t("comparisonSeriesTitle.percent")}: ` +
              percent +
              "%"
            );

          } else if (componentField === 'employmentInsights' && compareField === 'breakdown' && subField === 'gender') {
            const language = i18n.language
            let value = Math.abs(this.y)
            let percent
            if (language == "en") {
              percent = value
            } else if (language == "fr") {
              percent = value.toString().replace(".", ",")
            }
            return (
              "<b>" +
              this.series.name +
              "<br/>" + `${t("comparisonSeriesTitle.gender")}: ` +
              this.x +
              "</b><br/>" +
              `${t("comparisonSeriesTitle.percent")}: ` +
              percent +
              "%"
            );

          } else if (componentField === 'employmentInsights' && compareField === 'breakdown' && subField === 'immigrant') {
            const language = i18n.language
            let value = Math.abs(this.y)
            let percent
            if (language == "en") {
              percent = value
            } else if (language == "fr") {
              percent = value.toString().replace(".", ",")
            }
            return (
              "<b>" +
              this.series.name +
              "<br/>" +
              this.x +
              "</b><br/>" +
              `${t("comparisonSeriesTitle.percent")}: ` +
              percent +
              "%"
            );

          } else if (componentField === "employmentInsights") {
            return (

              "<b>" +
              this.series.name +
              "</b><br/>" +
              `<b>${t("comparisonSeriesTitle.count")}: ` +
              this.y.toLocaleString('en-CA') +
              "</b><br/>"
            )
          }

        },
      },
      plotOptions: componentField === 'employmentInsights' && compareField === 'breakdown' && (subField === 'age' || subField === 'ageRange' || subField === 'gender' || subField === 'immigrant') ? {
        series: {
          stacking: "normal",
        },
      } : {
        column: {
          pointPadding: 0.2,
          borderWidth: 2,
          borderRadius: {
            radius: 20,
          },
        },
        showInLegend: false,
        series: {
          name: {
            useHTML: true,
          },
        },
      },
      series: componentField === 'employmentInsights' && compareField === 'breakdown' && (subField === 'age' || subField === 'ageRange' || subField === 'gender' || subField === 'immigrant') ? seriesData : [
        {
          name: filter1Title,
          data: filter1Data,
          color: '#fbb518',
          pointWidth: window.innerWidth <= 768 ? 15 : componentField === 'employmentInsights' && (compareField === 'provinceTerritory' || compareField === 'sector') ? 15 : 25,
        },
        {
          name: filter2Title,
          data: filter2Data,
          color: {
            pattern: {
              path: {
                d: "M 0 0 L 10 10 M 9 -1 L 11 1 M -1 9 L 1 11",
                strokeWidth: 4,
                stroke: '#de9b61',
              },
              width: 10,
              height: 10,
            },
          },
          pointWidth: window.innerWidth <= 768 ? 15 : componentField === 'employmentInsights' && (compareField === 'provinceTerritory' || compareField === 'sector') ? 15 : 25,
        },
      ],
      legend: {
        enabled: true,
        itemStyle: {
          color: '#000', // Change color as desired
          fontWeight: 'bold',
          fontSize: '15px',

        },
        itemDistance: 100,
        align: 'center',
        verticalAlign: 'bottom'
      },
      responsive: {
        rules: [
          {
            condition: {
              maxWidth: 420,
            },
            chartOptions: {
              chart: {
                height: 400,
              },
              xAxis: {
                labels: {
                  style: {
                    fontSize: '10px',
                  },
                },
              },
              yAxis: {
                title: {
                  text: '',
                },
              },
              legend: {
                itemStyle: {
                  fontSize: '6px',
                },
              },
              plotOptions: {
                column: {
                  pointWidth: 15,
                },
              },
            },
          },
          {
            condition: {
              maxWidth: 768,
            },
            chartOptions: {
              chart: {
                height: 400,
              },
              xAxis: {
                labels: {
                  style: {
                    fontSize: '10px',
                  },
                },
              },
              yAxis: {
                title: {
                  text: '',
                },
              },
              legend: {
                itemStyle: {
                  fontSize: '12px',
                },
              },
              plotOptions: {
                column: {
                  pointWidth: 15,
                },
              },
            },
          },
          {
            condition: {
              minWidth: 769,
              maxWidth: 1200,
            },
            chartOptions: {
              chart: {
                height: 500,
              },
              xAxis: {
                labels: {
                  style: {
                    fontSize: '12px',
                  },
                },
              },
              yAxis: {
                title: {
                  text: chartTitle,
                },
              },
              legend: {
                itemStyle: {
                  fontSize: '14px',
                },
              },
              plotOptions: {
                column: {
                  pointWidth: 25,
                },
              },
            },
          },
          {
            condition: {
              minWidth: 1201,
            },
            chartOptions: {
              chart: {
                height: 600,
              },
              xAxis: {
                labels: {
                  style: {
                    fontSize: '14px',
                  },
                },
              },
              yAxis: {
                title: {
                  text: chartTitle,
                },
              },
              legend: {
                itemStyle: {
                  fontSize: '16px',
                },
              },
              plotOptions: {
                column: {
                  pointWidth: 30,
                },
              },
            },
          },
        ],
      },
    };

    // setOptions(options)
    // Render the chart
    if (dataset && Object.keys(dataset).length)
      Highcharts.chart("compare-chart-container", options);
  }, [dataset, t, window.innerWidth]);

  return (
    // <>
    // {dataset && Object.keys(dataset).length ? <HighchartsReact highcharts={Highcharts} options={options} /> : ""}
    // </>
    <div id="compare-chart-container">{/* Chart will be rendered here */}</div>
  );
};

export default CompareCharts;

const CustomIconWithTooltip = ({ iconContainerId, tooltipId }) => {
  const { t } = useTranslation()
  return (
    <div className="d-flex gap-1">

      <div id={`${iconContainerId}-1`} className="custom-icon" style={{ position: 'relative' }}>
        <OverlayTrigger
          placement="bottom"
          overlay={(props) => (
            <Tooltip {...props} className="tooltip-justify">

              <b>{t("disclaimer")}: </b> {t("disclaimerText").split('\\n').map((line, index, array) => (
                <React.Fragment key={index}>
                  {line}
                  {index < array.length - 1 && (
                    <div style={{ height: '7px' }} />)}
                </React.Fragment>
              ))}
            </Tooltip>
          )}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: '4px',
              width: "30px",
              height: "30px",
              backgroundColor: "#f0f0f0",
              cursor: 'pointer'
            }}
          >
            <FaInfoCircle style={{ fontSize: '20px', color: "#212529" }} />
          </div>
        </OverlayTrigger>
      </div>
    </div>
  );
};
