
// import { ToastContainer } from 'react-toastify';
import React, { useEffect } from 'react';
import './App.css';
import Navigation from "./navigation/index.jsx";
// import { useIntercom } from 'react-use-intercom';
// import { I18nextProvider } from 'react-i18next';
// import i18n from './translation/i18n.js';
import { UserProvider } from './context/UserContext';
import { BrowserRouter } from 'react-router-dom';

function App() {
  const router = Navigation();
  document.title = 'Food Processing';
  // const { boot } = useIntercom()

  // useEffect(() => {
  //   const user = JSON.parse(localStorage.getItem('user'))
  //   boot({ name: user?.name })
  // }, [boot])

  return (
    <>
      {/* <I18nextProvider i18n={i18n}>  */}
      {/* <BrowserRouter> */}
        <UserProvider>
          <Navigation />
        </UserProvider>
      {/* </BrowserRouter> */}
      {/* </Navigation> */}
      {/* </I18nextProvider> */}
    </>
  );
}

export default App;