import React, { useState, useRef, useEffect } from "react";
import { Tabs, Tab } from "react-bootstrap";

import TableViewEmploymentData from "./EmploymentDataComponents/TableViewEmploymentData";
import HorizontalChartEmploymentBySector from "./EmploymentDataComponents/HorizontalChartEmploymentBySector";
import RaceChart from "./EmploymentDataComponents/RaceChart";
import SemiPieChartView from "./EmploymentDataComponents/SemiPieChartView";
import SemiPieChartViewImmigrants from "./EmploymentDataComponents/SemiPieChartViewImmigrants";
import PyramidChart from "./EmploymentDataComponents/PyramidChart";
import LineChart from "./charts/LineChart";
import Comparison from "../pages/dashboard/Comparison.tsx";
import Dashboard from "../pages/dashboard/Dashboard.tsx";
import Dashboard2 from "../pages/dashboard/Dashboard2.tsx";
import Error403 from "./Error403.jsx";
import { useTranslation } from "react-i18next";
import { useUser } from '../context/UserContext';
import { Loader } from "./Loader.tsx";

const TabEstablishment = ({ getTabData, joyrideStatus, setJoyrideStatus }) => {
  const containerRef = useRef(null);
  const { permissions, joyrideSteps } = useUser();
  const { t } = useTranslation();

  const isSuperAdmin = permissions.includes('full');
  const [state, setState] = useState(null);
  const [loading, setLoading] = useState(true)

  const handleTab = (k) => {
    setKey(k);
    getTabData('tabcomponent', k);
  };

  const getDefaultActiveTab = () => {
    if (isSuperAdmin || (permissions?.includes('establishmentInsights') && permissions?.includes('establishmentTabProvince'))) {
      return 'tab1';
    }
    if (permissions.includes('establishmentInsights') && permissions.includes('establishmentTabSector')) {
      return 'tab2';
    }
    if (permissions.includes('establishmentInsights') && permissions.includes('establishmentTabComparison')) {
      return 'tab3';
    }
    return null; // Default if no permissions match
  };

  const [key, setKey] = useState();
  const [overflowStyle, setOverflowStyle] = useState("initial");

  const handleSectorData = (value) => {
    if (permissions.includes('full') || (permissions.includes('establishmentInsights') && permissions.includes('establishmentTabSector'))){
      setState(value);
      setKey('tab2');
      getTabData('tabcomponent', 'tab2');
    }else{
      setKey('tab1');
    } 
  };

  const hasPermissions = () => (
    isSuperAdmin || (
      permissions.includes('establishmentInsights') && (
        permissions.includes('establishmentTabProvince') ||
        permissions.includes('establishmentTabSector') ||
        permissions.includes('establishmentTabComparison')
      )
    )
  );

  useEffect(() => {
    if (permissions && permissions.length > 0) {
      const defaultTab = getDefaultActiveTab();
      // console.log(defaultTab);
      setKey(defaultTab);
      setLoading(false)
    }
  }, [permissions]);

  // Monitor overflow in tab container
  useEffect(() => {
    const container = containerRef.current;
    if (container && container.scrollHeight > container.clientHeight) {
      setOverflowStyle("auto");
    } else {
      setOverflowStyle("initial");
    }
  }, [key, state]);

  if (loading) {
    return <Loader show={loading}/>;
  }

  // Show Error403 if no permissions
  if (!hasPermissions()) {
    return <Error403 />;
  }

  return (
    <div style={{ display: "flex", flexDirection: "column"}}>
      {/* Fixed Tabs */}
      <div className="tab-scroll-container">
      <Tabs
        id="controlled-tab"
        activeKey={key}
        onSelect={handleTab}
        className="custom_tab"
      >
        {(isSuperAdmin || (permissions.includes('establishmentInsights') && permissions.includes('establishmentTabProvince'))) && (
          <Tab eventKey="tab1" title={t("workforceInsights.tabTitle.provinceAndTerritory")}
            className="tab_container mt-2"
          />
        )}
        {(isSuperAdmin || (permissions.includes('establishmentInsights') && permissions.includes('establishmentTabSector'))) && (
          <Tab eventKey="tab2" title={t("workforceInsights.tabTitle.sector")}
            className="tab_container mt-2"
          />
        )}
        {(isSuperAdmin || (permissions.includes('establishmentInsights') && permissions.includes('establishmentTabComparison'))) && (
          <Tab eventKey="tab3" title={t("commonTitleComparison")}
            className="tab_container mt-2"
          />
        )}
      </Tabs>
      </div>

      {/* Scrollable Content Area */}
      <div ref={containerRef} className='tab_container' style={{ flex: 1, overflowY: "auto", padding: "20px", marginBottom: "10px", maxHeight: "calc(100vh - 100px)"}}>
      {(key === "tab1" && (permissions.includes('full') || (permissions.includes('establishmentInsights') && permissions.includes('establishmentTabProvince')))) && (
          <Dashboard handleSectorData={handleSectorData} joyrideStatus={joyrideStatus} setJoyrideStatus={setJoyrideStatus} steps={joyrideSteps?.establishmentInsights?.tabProvinceTerritory} />
        )}
        {(key === "tab2" && (isSuperAdmin || (permissions.includes('establishmentInsights') && permissions.includes('establishmentTabSector')))) && (
          <Dashboard2 state={state} joyrideStatus={joyrideStatus} setJoyrideStatus={setJoyrideStatus} steps={joyrideSteps?.establishmentInsights?.tabSector} />
        )}
        {(key === "tab3" && (isSuperAdmin || (permissions.includes('establishmentInsights') && permissions.includes('establishmentTabComparison')))) && (
          <Comparison field={'establishmentCount'} steps={joyrideSteps?.comparison} joyrideStatus={joyrideStatus} setJoyrideStatus={setJoyrideStatus} tabSettings={getTabData} />
        )}
      </div>
    </div>
  );
};

export default TabEstablishment;
