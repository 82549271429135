import React, { createContext, useContext, useState, useEffect } from 'react';
import { jwtDecode } from 'jwt-decode';
import httpClient from '../services/httpClient.ts';
import API_URLS from '../constants/apiUrls.ts';

const UserContext = createContext();

export const UserProvider = ({ children }) => {

    const [user, setUser] = useState(null);
    const [permissions, setPermissions] = useState([]);
    const [joyrideSteps, setJoyrideSteps] = useState([])

    const fetchUserPermissions = (id) => {
        
            httpClient({
                method: "get",
                url: API_URLS.getPermissions.replace("{id}", id),
            })
                .then(({ result, error }) => {
                    if (result) {
                        setPermissions(result?.role?.permissions);
                    }
                })
                .catch((error) => {
                    console.error("Error:", error);
                });
    }

    const fetchJoyrideSteps = () => {
         
        httpClient({
            method: "get",
            url: API_URLS.getJoyrideSteps,
        })
            .then(({ result, error }) => {
                if (result) {
                    setJoyrideSteps(result)
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    }

    useEffect(() => {
       
        const storedUser = localStorage.getItem('user');
        const storedToken = localStorage.getItem('token');
        if (storedUser && storedToken) {
            try {
                const decodedToken = jwtDecode(storedToken);
                setUser(JSON.parse(storedUser));
                fetchUserPermissions(JSON.parse(storedUser).role)
                fetchJoyrideSteps()
                
            } catch (error) {
                console.error("Failed to decode token:", error);
                // Optional: Clear invalid token from storage
                localStorage.removeItem("token");
                localStorage.removeItem("user");
                setUser(null);
                setPermissions([]);
                
            }
        }
    }, []);

    const updateUserPermissions = () => {
        fetchUserPermissions(user.role)
    }

    const login = (userData, token) => {
        setUser(userData);
        fetchUserPermissions(userData.role)
        fetchJoyrideSteps()
    };

    const logout = () => {
        setUser(null);
        setPermissions([]);
        localStorage.removeItem('user');
        localStorage.removeItem('token');
    };

    return (
        <UserContext.Provider value={{ user, permissions, joyrideSteps, updateUserPermissions, fetchJoyrideSteps, login, logout }}>
            {children}
        </UserContext.Provider>
    );
};

export const useUser = () => useContext(UserContext);
