import React from 'react'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CommonHeader from "../../components/Header.tsx";
import TabSettings from '../../components/TabSetting.js';
import { useTranslation } from 'react-i18next';

const Settings = () => {
  const { t } = useTranslation()
  return (
    <>
      <ToastContainer />
      <div className="main-content" style={{ display: 'flex', flexDirection: 'column', marginBottom: "10px" }}>
        <CommonHeader
          title={t("settings.headerTitle")}
          component= {'settings'}
        />
        <div style={{ flex: 1 }}>
          <TabSettings />
        </div>
      </div>
    </>
  )
}

export default Settings