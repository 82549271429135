import React, { useRef, useEffect, useState } from "react";
// import CanadaMapJson from "../../components/CanadaMap/CanadaMap.json";
import API_URLS from "./../../constants/apiUrls.ts";
import httpClient from "../../services/httpClient.ts";
import { Card, ListGroup, OverlayTrigger, Tooltip, Modal, Button } from "react-bootstrap";
import { MultiSelectCheckbox } from "../../components/UI/MultiSelectCheckbox.tsx";
import { Loader } from "../../components/Loader.tsx";
import Highcharts from "highcharts/highmaps";
import canadaMap from "@highcharts/map-collection/countries/ca/ca-all.geo.json";
import HighchartsReact from "highcharts-react-official";
import { useTranslation } from "react-i18next";
import { FaInfoCircle, FaQuestionCircle } from 'react-icons/fa';
import { BiSolidVideos } from "react-icons/bi";
import ReactPlayer from 'react-player';
import dummyImage from '../../assets/rb_2148319261.png'
// import dummyVideo from '../../assets/Dummy Video For Website.mp4'
import useWindowWidth from "../../hooks/useWindowWidth.js";
import WelcomeHelpModal from "../../components/WelcomeHelpModal.tsx";
import ReactDOM from 'react-dom';
import {
  capitalizeString,
} from "../../services/commonFn.js";
import HelpTour from "../../components/HelpTour.tsx";

const Dashboard = ({ steps, handleSectorData, joyrideStatus, setJoyrideStatus }) => {
  const { t, ready } = useTranslation();
  const windowWidth = useWindowWidth()
  const [stepStates, setStepStates] = useState(
    steps?.map(() => ({ showVideo: false })) || []
  );
  const [joyrideKey, setJoyrideKey] = useState(0);
  const [helpRun, setHelpRun] = useState(false)
  const businessSizeRef = useRef<string | null>(null);
  const selectPeriodRef = useRef<number | null>(null);
  const selectYearRef = useRef<string | null>(null);;
  const chartRef = useRef(null); // Ref to hold the Highcharts chart instance
  const multiSelectRef = useRef<(() => void) | null>(null);
  interface Child {
    checked: boolean;
  }

  interface Parent {
    checked: boolean;
    children: Child[];
  }

  const [businessTypeList, setBusinessTypeList] = useState<any>([]);
  const [establishmentList, setEstablishmentList] = useState<any>([]);
  const [insights, setInsights] = useState<any>(null);
  const [totalManufactures, setTotalManufactures] = useState(null)
  const [employmentSize, setEmploymentSize] = useState("");
  const [selectBusinessType, setSelectBusinessType] = useState("")
  const [selectedSector, setSelectedSector] = useState<any>([]);
  const [selectedSectorName, setSelectedSectorName] = useState<any>([])
  const [sectorData, setSectorData] = useState<any>([]);
  const [years, setYears] = useState<any>([]);
  const [selectedPeriod, setSelectedPeriod] = useState<number | undefined>(undefined);
  const [selectedYear, setSelectedYear] = useState<any>();
  const [show, showLoader] = useState(false);
  const [isLoading, setIsLoading] = useState(true)
  const [showVideoModal, setShowVideoModal] = useState(false)
  const [showHelpTourModal, setShowHelpTourModal] = useState(false)
  const [playing, setPlaying] = useState(false)
  const [joyrideSteps, setSteps] = useState([])
  const [mapData, setMapData] = useState([])
  const [options, setOptions] = useState({
    chart: {
      map: canadaMap,
      height: 700, // Adjust the height of the chart
      events: {
        load: function () {
          const chart = this;
          const iconContainerId = 'icon-container-map';
          const tooltipId = 'icon-tooltip-map';

          const addIconWithTooltip = () => {
            // Remove the existing container if it exists
            let existingIconContainer = document.getElementById(iconContainerId);
            if (existingIconContainer) existingIconContainer.remove();

            // Create a parent div to hold both icons
            let parentDiv = document.createElement('div');
            parentDiv.className = 'icons-container';
            parentDiv.style.position = 'absolute'; // Position the container
            parentDiv.style.display = 'flex'
            parentDiv.style.gap = '5px'

            // // Ensure the chart container exists before appending elements
            if (chart.container) {
              //   // Set chart container position to relative
              chart.container.style.position = 'relative';
              chart.container.appendChild(parentDiv);
            }

            // Render the React icons inside the divs
            try {
              ReactDOM.render(
                <CustomIconWithTooltip
                  iconContainerId={iconContainerId}
                  tooltipId={tooltipId}
                  onStart={() => setHelpRun(true)}
                />,
                parentDiv
              );
              // ReactDOM.render(<FaQuestionCircle />, iconDiv2);
            } catch (error) {
              console.error("Error rendering the icons:", error);
            }

            // Update the position of the parent div
            const updateParentDivPosition = () => {
              if (chart.container) {
                parentDiv.style.right = '25px';
                parentDiv.style.top = '10px';
              }
            };

            updateParentDivPosition();
            Highcharts.addEvent(chart, 'redraw', updateParentDivPosition);
            window.addEventListener('resize', updateParentDivPosition);
          };
          addIconWithTooltip();
        }
      }
    },
    title: {
      text: t("dashboardCardTwoTitle1"),
    },
    subtitle: {
      text: "", // Add your subtitle text here
    },
    mapNavigation: {
      enabled: true,
      buttonOptions: {
        alignTo: "spacingBox",
      },
    },
    mapView: {
      zoom: 2,
    },
    colorAxis: {
      min: 0,
      stops: [
        [0, "#faedee"],
        [0.5, "#f7929a"],
        [1, "#E61E28"],
      ],
    },
    credits: {
      enabled: false,
    },
    series: [
      {
        name: t("dashboardSeriesName"),
        states: {
          hover: {
            color: "#112144",
            // enabled: false,
            borderColor: "#fff",
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function () {
            return this.point.name + '<br>' + this.point.value.toLocaleString('en-CA') + ` (${this.point.percentage})`;
          },
          style: {
            fontSize: "9px",
            cursor: "pointer",
            textAlign: "center"
          },
          allowOverlap: true,
          align: 'center', // Adjust alignment to reduce overlap
          verticalAlign: 'top', // Adjust vertical alignment
          overflow: false, // Justify labels that are outside the plot area,
        },
        allAreas: false,
        data: [],
      },
    ],
    plotOptions: {
      series: {
        point: {
          events: {
            click: function () {
              handleSectorData({
                id: this.name.toLowerCase(),
                empSize: businessSizeRef.current,
                period: selectPeriodRef.current,
                year: selectYearRef.current,
              })
            },
          },
        },
        cursor: "pointer",
      },
    },
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 768, // Mobile screens
          },
          chartOptions: {
            chart: {
              height: 700
            },
            series: [
              {
                dataLabels: {
                  style: {
                    fontSize: '10px'
                  }
                }
              },
            ],
          },
        },
        {
          condition: {
            maxWidth: 430, // Target mobile screens (maxWidth for mobile)
          },
          chartOptions: {
            chart: {
              height: 400
            },
            title: {
              style: {
                fontSize: "13px"
              }
            },
            subtitle: {
              style: {
                fontSize: "11px"
              }
            },
            // series: [
            //   {

            //   },
            // ],
          },
        },
      ]
    },
  });

  const handleHelpTour = (value) => {
    const { action, index, origin, status, type } = value;
    
    if (action === 'reset' || action === 'close') {
      setHelpRun(false)
      setJoyrideStatus(false)
      setStepStates(steps?.map(() => ({ showVideo: false })) || [])
    }
  }

  useEffect(() => {
    if (joyrideStatus) {
      
      const joyrideSteps = steps?.map((step, index) => {
        // Define content based on showVideo state
        // const contentWithVideo = (
        //   <div style={{ maxHeight: "300px", overflowY: "auto" }}>
        //     <button
        //       onClick={() =>
        //         setStepStates((prev) =>
        //           prev.map((state, i) =>
        //             i === index ? { ...state, showVideo: !state.showVideo } : state
        //           )
        //         )
        //       }
        //       style={{outline: 'none'}}
        //     >
        //       {stepStates[index]?.showVideo ? '' : <BiSolidVideos fontSize={20}/>}
        //     </button>
        //     {stepStates[index]?.showVideo && (
        //       <div
        //         className="card"
        //         style={{ height: "250px", width: "330px" }}
        //       >
        //         <ReactPlayer
        //           config={{
        //             file: { attributes: { controlsList: "nodownload" } },
        //           }}
        //           style={{
        //             borderRadius: "10px",
        //             overflow: "hidden",
        //             backgroundColor: "#020202",
        //           }}
        //           url={step?.videoUrl}
        //           controls={true}
        //           height="250px"
        //           width="330px"
        //         />
        //       </div>
        //     )}
        //     <br />
        //     <p>
        //       {t(step.content)
        //         .split("\\n")
        //         .map((line, idx) => (
        //           <p key={idx}>{line}</p>
        //         ))}
        //     </p>
        //   </div>
        // );
        const contentWithVideo = (
          <div style={{ maxHeight: "300px", overflowY: "auto", display: 'flex', flexDirection: 'column' }}>
            {/* Button on the left side */}
            <button
              onClick={() =>
                setStepStates((prev) =>
                  prev.map((state, i) =>
                    i === index ? { ...state, showVideo: !state.showVideo } : state
                  )
                )
              }
              style={{ outline: 'none', alignSelf: 'flex-start', border: 'none' }}
            >
              {stepStates[index]?.showVideo ? '' : <BiSolidVideos fontSize={20} />}
            </button>
            
            {/* Video Content */}
            {stepStates[index]?.showVideo && (
              <div className="card" style={{ height: "250px", width: "330px" }}>
                <ReactPlayer
                  config={{
                    file: { attributes: { controlsList: "nodownload" } },
                  }}
                  style={{
                    borderRadius: "10px",
                    overflow: "hidden",
                    backgroundColor: "#020202",
                  }}
                  url={step?.videoUrl}
                  controls={true}
                  height="250px"
                  width="330px"
                />
              </div>
            )}
            
            {/* Content Text */}
            <br />
            <p>
              {t(step.content)
                .split("\\n")
                .map((line, idx) => (
                  <p key={idx}>{line}</p>
                ))}
            </p>
          </div>
        );
  
        const translatedContent = (
          <>
            {t(step.content)
              .split("\\n")
              .map((line, idx) => (
                <p key={idx}>{line}</p>
              ))}
          </>
        );
  
        return {
          ...step,
          content: step?.videoUrl ? contentWithVideo : translatedContent,
          disableCloseOnEsc: true,
          disableBeacon: true,
          showSkipButton: false,
          showNextButton: true,
          closeButtonText: "Close",
          placement: window.innerWidth <= 768 ? "top" : step.placement,
        };
      });
  
      // Update steps and enable Joyride
      setSteps(joyrideSteps);
      setHelpRun(true);
    }
  }, [joyrideStatus, stepStates]); // Include dependencies to re-run effect when states change

  // useEffect(() => {
  //   if (joyrideStatus) {
  //     // setShowHelpTourModal(true)
  //     const joyrideSteps = steps?.map(step => {
  //       const contentWithVideo = (
  //         <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
  //           <div className="card" style={{ height: '250px', width: '330px' }}>
  //             <ReactPlayer
  //               config={{ file: { attributes: { controlsList: 'nodownload' } } }}
  //               style={{
  //                 borderRadius: '10px',
  //                 overflow: 'hidden',  
  //                 backgroundColor: "#020202"
  //               }}
  //               url={step?.videoUrl}
  //               controls={true}
  //               height="250px"  
  //               width="330px"
  //             />
  //           </div>
  //           <br />
  //           <p>
  //             {t(step.content).split('\\n').map((line, index, array) => (
  //               <p key={index}>{line}</p>
  //             ))}
  //           </p>
  //         </div>

  //       )
  //       const translatedContent = (<>
  //         {t(step.content).split('\\n').map((line, index, array) => (
  //           <p key={index}>{line}</p>
  //         ))}
  //       </>)
  //       return {
  //         ...step,
  //         content: step?.videoUrl ? contentWithVideo : translatedContent,
  //         disableCloseOnEsc: true,
  //         disableBeacon: true,
  //         showSkipButton: false,
  //         showNextButton: true,
  //         closeButtonText: "Close",
  //         placement: window.innerWidth <= 768 ? 'top' : step.placement
  //       };
  //     })
  //     setSteps(joyrideSteps)
  //     setHelpRun(true)
  //   }
  // }, [joyrideStatus])

  const sectorInsights = (selectedsector) => {

    if (selectedsector?.parents?.length === 0 && selectedsector?.children?.length === 0) {
      return setSelectedSectorName("")
    } else if (selectedsector?.parents?.length > 0) {
      let insight, parentString
      const selectedParentsSectorName = selectedsector?.parents
        ?.filter(item => item.allChildrenChecked) // Select only items with allChildrenChecked as true
        ?.map(item => t(item.languageKey));
      const selectedChildrenSectorName = selectedsector?.children?.map(item => t(item.languageKey));

      if (selectedParentsSectorName.length > 1) {
        const lastItem = selectedParentsSectorName.pop();
        parentString = selectedParentsSectorName.join(", ") + ` ${t("and")} ` + lastItem
      } else {
        parentString = selectedParentsSectorName[0]
      }

      if (selectedParentsSectorName.length > 0 && selectedChildrenSectorName.length > 0) {
        const lastItem = selectedChildrenSectorName.pop();
        if (selectedParentsSectorName.length > 0 && selectedChildrenSectorName.length > 1) {
          insight = parentString + ` ${t('sectors')} ${t("and")} ` + selectedChildrenSectorName.join(", ") + ` ${t("and")} ` + lastItem;
        } else {
          insight = parentString + ` ${t('sectors')} ` + selectedChildrenSectorName.join(", ") + ` ${t("and")} ` + lastItem;
        }

      } else if (selectedParentsSectorName.length === 0 && selectedChildrenSectorName.length > 0) {
        const lastItem = selectedChildrenSectorName.pop();
        insight = selectedChildrenSectorName.join(", ") + ` ${t("and")} ` + lastItem; // Handle single item or empty array
      } else if (selectedParentsSectorName.length > 0 && selectedChildrenSectorName.length === 0) {
        insight = parentString
      }

      return setSelectedSectorName(insight)
    } else if (selectedsector?.parents?.length > 0) {
      let insight
      const selectedSectorName = selectedsector?.parents?.map(item => t(item.languageKey));

      if (selectedSectorName.length > 1) {
        const lastItem = selectedSectorName.pop(); // Remove the last item
        insight = selectedSectorName.join(", ") + ` ${t("and")} ` + lastItem;
      } else {
        insight = selectedSectorName[0] || ""; // Handle single item or empty array
      }

      return setSelectedSectorName(insight)
    }
  }

  const capitalize = (str) => {
    return str
      .split(",")
      .map((word) => {
        return word.trim().charAt(0).toUpperCase() + word.trim().slice(1);
      })
      .join(", ");
  };

  function calculatePercentage(count, totalCount) {
    const percentage = (count / totalCount) * 100;

    if (percentage < 1) {
      return "<1%";
    } else {
      return percentage.toFixed(2) + "%";
    }
  }

  function findEmploymentSizeName(): string {
    if (employmentSize === "") {
      return t("dashboardCardTwoEmploymentSizeBlank");
    }

    if (employmentSize === "null") {
      return t("dashboardCardTwoEmploymentSizeNull");
    }

    if (businessTypeList && businessTypeList.length > 0) {
      const employmentSizeObject = businessTypeList.find(
        (item) => item?._id === employmentSize
      );

      return employmentSizeObject
        ? t("dashboardCardTwoEmploymentSizeValue") +
        ", " +
        t(employmentSizeObject.languageKey).replace("total,", "")
        : "";
    }
    // Default return value if none of the conditions are met
    return "";
  }

  const handleEmploymentSizeChange = (event) => {
    setEmploymentSize(event.target.value);
    const businessType = businessTypeList.filter(business => business._id === event.target.value)
    setSelectBusinessType(businessType.length > 0 ? businessType[0]?.languageKey : "")
  };

  const handleSectorChange = (event) => {
    const checkedParents: Parent[] = [];
    const checkedChildren: Child[] = [];

    event.forEach((option) => {
      // Check if all children are checked
      const allChildrenChecked = option.children.every(child => child.checked);

      // If the parent itself is checked or all children are checked, push to checkedParents
      if (option.checked || allChildrenChecked) {
        checkedParents.push({
          ...option,
          allChildrenChecked: allChildrenChecked
        });
      }

      // If not all children are checked, push individual checked children
      if (!allChildrenChecked) {
        option.children.forEach((child) => {
          if (child.checked) {
            checkedChildren.push(child);
          }
        });
      }
    });

    // Update selectedSector with parents and children
    setSelectedSector({ ...selectedSector, parents: checkedParents, children: checkedChildren });

    // Call sectorInsights with updated sector
    sectorInsights({ ...selectedSector, parents: checkedParents, children: checkedChildren });
  };

  // Change the period
  const handlePeriodChange = (event) => {
    setSelectedPeriod(event.target.value);
  };

  // Change the year 
  const handleYearChange = (event) => {
    setSelectedYear(JSON.parse(event.target.value));
    setSelectedPeriod(JSON.parse(event.target.value)?.period?.some((i) => i.value === selectedPeriod) ? selectedPeriod : 1)
  };

  // Reset the default values
  const clearAll = () => {
    setEmploymentSize("");
    setSelectedSector([]);
    setSelectedYear(years?.[0]);
    setSelectedPeriod(years?.[0]?.period?.length > 1 ? 2 : 1);
    setSelectedSectorName("")
    multiSelectRef.current?.();
  };

  // Gets the data for seclected sector(s) for particular period and year
  const getBusinessCountByGeography = () => {
    // showLoader(true);
    let filterString: string = "?";
    if (employmentSize) {
      filterString += "businessType=" + employmentSize + "&";
    }
    if (selectedSector.parents && selectedSector?.parents?.length) {
      const parentsWithAllChildrenChecked = selectedSector.parents
        .filter(parent => parent.allChildrenChecked)
        .map(parent => parent._id).join(",");
      filterString +=
        "parentEstablishment=" +
        parentsWithAllChildrenChecked +
        "&";
    }

    if (selectedSector.children && selectedSector.children.length) {
      const filteredChildren = selectedSector.children.filter(child => {
        // Check if this child exists in any parent's children with allChildrenChecked=true
        return !selectedSector.parents.some(parent =>
          parent.allChildrenChecked &&
          parent.children?.some(parentChild => parentChild._id === child._id)
        );
      });

      // If there are remaining children after filtering, add them to the filter string
      if (filteredChildren.length) {
        filterString +=
          "childEstablishment=" +
          filteredChildren.map(child => child._id).join(",") +
          "&";
      }
    }

    if (selectedPeriod) {
      filterString += "period=" + selectedPeriod + "&";
    }
    if (selectedYear) {
      filterString += "year=" + selectedYear.year + "&";
    }

    filterString = filterString.slice(0, -1);

    let url = API_URLS.businessCountByGeography + filterString;
    httpClient({
      method: "get",
      url,
    })
      .then(({ result, error }) => {
        if (result) {
          if (result.counts && result.counts.length) {
            let values: any = [];
            setInsights(result.insights);

            const sectorDataCanada = result?.counts?.filter(item => item?.geography === 'canada')

            setSectorData(sectorDataCanada?.[0].parentEstablishments)
            const manufactureData = result?.counts?.filter(item => item?.geography === 'canada')
            setTotalManufactures(manufactureData[0]?.totalCount)

            for (const countObj of result.counts) {
              for (const geographyObj of canadaMap.features) {
                if (
                  geographyObj.properties.name
                    ?.replace(/é/g, "e")
                    .toLowerCase() === countObj.geography
                ) {
                  // let hcKey = geographyObj.properties["hc-key"];
                  let dataLabelName = capitalizeString(t(countObj.languageKey));
                  let totalCount = countObj.totalCount;

                  // Update properties and push data to values
                  geographyObj.properties["percentage"] = calculatePercentage(
                    totalCount,
                    result.counts[0].totalCount
                  );

                  let yPosition = 0;
                  let xPosition = 0
                  if (windowWidth <= 768) {
                    if (countObj.languageKey === 'provincePrinceEdwardIsland') {
                      yPosition = -30 + 5;
                      xPosition = 12 - 30
                    } else if (countObj.languageKey === 'provinceNewfoundlandAndLabrador') {
                      yPosition = -40
                      xPosition = 35
                    } else if (countObj.languageKey === 'provinceNewBrunswick') {
                      yPosition = -8 + 3
                      xPosition = -7
                    } else if (countObj.languageKey === 'provinceNovaScotia') {
                      yPosition = 10
                      xPosition = 20 - 15
                    } else if (countObj.languageKey === 'provinceBritishColumbia') {
                      yPosition = -30
                      xPosition = -20 + 30
                    } else if (countObj.languageKey === 'provinceAlberta') {
                      yPosition = -25 + 15
                      xPosition = 0
                    } else if (countObj.languageKey === 'provinceSaskatchewan') {
                      yPosition = 15
                      xPosition = 0
                    } else if (countObj.languageKey === 'provinceManitoba') {
                      yPosition = -10 - 15
                      xPosition = 0 + 10
                    } else if (countObj.languageKey === 'provinceNunavut') {
                      yPosition = -15 - 50
                      xPosition = 15
                    } else if (countObj.languageKey === 'provinceNorthwestTerritories') {
                      yPosition = 0 - 10
                      xPosition = 15 + 20
                    } else if (countObj.languageKey === 'provinceYukon') {
                      yPosition = -20
                      xPosition = 0
                    } else if (countObj.languageKey === 'provinceQuebec') {
                      yPosition = -20 - 15
                      xPosition = 0
                    }
                  } else {
                    if (countObj.languageKey === 'provincePrinceEdwardIsland') {
                      yPosition = -30;
                      xPosition = 12
                    } else if (countObj.languageKey === 'provinceNewfoundlandAndLabrador') {
                      yPosition = -40
                      xPosition = 35
                    } else if (countObj.languageKey === 'provinceNewBrunswick') {
                      yPosition = -8
                      xPosition = -7
                    } else if (countObj.languageKey === 'provinceNovaScotia') {
                      yPosition = 10
                      xPosition = 20
                    } else if (countObj.languageKey === 'provinceBritishColumbia') {
                      yPosition = -30
                      xPosition = -20
                    } else if (countObj.languageKey === 'provinceAlberta') {
                      yPosition = -25
                      xPosition = 0
                    } else if (countObj.languageKey === 'provinceSaskatchewan') {
                      yPosition = 15
                      xPosition = 0
                    } else if (countObj.languageKey === 'provinceManitoba') {
                      yPosition = -10
                      xPosition = 0
                    } else if (countObj.languageKey === 'provinceNunavut') {
                      yPosition = -15
                      xPosition = 15
                    } else if (countObj.languageKey === 'provinceNorthwestTerritories') {
                      yPosition = 0
                      xPosition = 15
                    }
                  }


                  values.push({
                    'hc-key': geographyObj.properties["hc-key"],
                    value: totalCount,
                    name: dataLabelName,
                    percentage: geographyObj.properties["percentage"],
                    dataLabels: {
                      y: yPosition,
                      x: xPosition
                    }
                  });
                }
              }
            }

            setMapData(values.map(point => {
              // Apply condition to update dataLabels
              if (point['hc-key'] === 'ca-bc') {
                return {
                  ...point,
                  dataLabels: {
                    ...point.dataLabels,
                    y: point.dataLabels.y, // Update y position for 'us'
                    x: point.dataLabels.x + 25  // Update x position for 'us'
                  }
                };
              }
              else if (point['hc-key'] === 'ca-yt') {
                return {
                  ...point,
                  dataLabels: {
                    ...point.dataLabels,
                    y: point.dataLabels.y - 20, // Update y for percentage < 50
                    x: point.dataLabels.x   // Update x for percentage < 50
                  }
                };
              }
              else if (point['hc-key'] === 'ca-nt') {
                return {
                  ...point,
                  dataLabels: {
                    ...point.dataLabels,
                    y: point.dataLabels.y - 10, // Update y for percentage < 50
                    x: point.dataLabels.x + 20  // Update x for percentage < 50
                  }
                };
              }
              else if (point['hc-key'] === 'ca-nu') {
                return {
                  ...point,
                  dataLabels: {
                    ...point.dataLabels,
                    y: point.dataLabels.y - 50, // Update y for percentage < 50
                    x: point.dataLabels.x   // Update x for percentage < 50
                  }
                };
              }
              else if (point['hc-key'] === 'ca-ab') {
                return {
                  ...point,
                  dataLabels: {
                    ...point.dataLabels,
                    y: point.dataLabels.y + 15, // Update y for percentage < 50
                    x: point.dataLabels.x  // Update x for percentage < 50
                  }
                };
              }
              else if (point['hc-key'] === 'ca-pe') {
                return {
                  ...point,
                  dataLabels: {
                    ...point.dataLabels,
                    y: point.dataLabels.y + 5, // Update y for percentage < 50
                    x: point.dataLabels.x - 30 // Update x for percentage < 50
                  }
                };
              }
              else if (point['hc-key'] === 'ca-qc') {
                return {
                  ...point,
                  dataLabels: {
                    ...point.dataLabels,
                    y: point.dataLabels.y - 25, // Update y for percentage < 50
                    x: point.dataLabels.x  // Update x for percentage < 50
                  }
                };
              }
              else if (point['hc-key'] === 'ca-nb') {
                return {
                  ...point,
                  dataLabels: {
                    ...point.dataLabels,
                    y: point.dataLabels.y + 3, // Update y for percentage < 50
                    x: point.dataLabels.x  // Update x for percentage < 50
                  }
                };
              }
              else if (point['hc-key'] === 'ca-ns') {
                return {
                  ...point,
                  dataLabels: {
                    ...point.dataLabels,
                    y: point.dataLabels.y, // Update y for percentage < 50
                    x: point.dataLabels.x - 15 // Update x for percentage < 50
                  }
                };
              }
              else if (point['hc-key'] === 'ca-mb') {
                return {
                  ...point,
                  dataLabels: {
                    ...point.dataLabels,
                    y: point.dataLabels.y - 15, // Update y for percentage < 50
                    x: point.dataLabels.x + 10 // Update x for percentage < 50
                  }
                };
              }
              else {
                return point; // No changes for other points
              }
            }))
            setOptions((prevOptions) => ({
              ...prevOptions,
              series: [
                {
                  ...prevOptions.series[0],
                  data: values,
                  name: t("dashboardSeriesName")
                },
              ],

            }));
          } else {
            let values: any = [];
            for (const geographyObj of canadaMap.features) {
              values.push([geographyObj.properties["hc-key"], 0]);
              geographyObj.properties["percentage"] = "0%";
            }

            setOptions((prevOptions) => ({
              ...prevOptions,
              series: [
                {
                  ...prevOptions.series[0],
                  data: values,
                },
              ],

            }));
          }
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      })
      .finally(() => {
        // showLoader(false)
      });
  };

  const updateUserSettings = () => {
    let user = localStorage.getItem("user");
    let parseUserLocal = user ? JSON.parse(user) : null;
    let parseUserSetting = parseUserLocal?.settings;
    let url = API_URLS.preloadedSettings;
    let data = {
      settings: {
        ...parseUserSetting,
        establishment: {
          ...parseUserSetting.establishment,
          geographyInsights: {
            sectors: selectedSector,
            year: JSON.stringify(selectedYear),
            period: selectedPeriod,
            employmentSize: employmentSize,
          },
        }
      },
    };

    httpClient({
      method: "put",
      url,
      data,
    }).then(({ result, error }) => {
      if (result) {
        let datatoSave = {
          ...parseUserLocal,
          settings: {
            ...result?.settings,
          },
        };
        localStorage.setItem("user", JSON.stringify(datatoSave));
      }
    });
  }

  useEffect(() => {
    try {
      const initialize = async () => {
        try {
          showLoader(true);
          let user = localStorage.getItem("user")
          let parseUserSetting = user ? JSON.parse(user)?.settings : null;
          const urls = {
            listBusinessTypes: API_URLS.listBusinessTypes,
            businessCountYears: API_URLS.businessCountYearsList,
            listParentAndChildren: API_URLS.listParentAndChildren,
          };

          // Execute all API calls concurrently
          const [listBusinessTypesResponse, businessCountYearsResponse, listParentAndChildrenResponse] = await Promise.all([
            httpClient({ method: "get", url: urls.listBusinessTypes }),
            httpClient({ method: "get", url: urls.businessCountYears }),
            httpClient({ method: "get", url: urls.listParentAndChildren })
          ]);

          if (listBusinessTypesResponse.result) {
            setBusinessTypeList(listBusinessTypesResponse.result.businessTypes)
          }

          if (businessCountYearsResponse.result) {
            setYears(businessCountYearsResponse.result.years)
            setSelectedYear(parseUserSetting.establishment?.geographyInsights?.year ? JSON.parse(parseUserSetting.establishment?.geographyInsights?.year) : businessCountYearsResponse.result.years[0])
            setSelectedPeriod(
              parseUserSetting.establishment?.geographyInsights?.period
                ? businessCountYearsResponse.result.years[0].period.some(
                  (i) => Number(i.value) === Number(parseUserSetting.establishment?.geographyInsights?.period)
                )
                  ? Number(parseUserSetting.establishment?.geographyInsights?.period)
                  : businessCountYearsResponse.result.years[0].period.length > 1
                    ? 2
                    : 1
                : 1
            );
          }

          if (listParentAndChildrenResponse.result) {
            setEstablishmentList(listParentAndChildrenResponse.result.establishments.filter(item => item.name !== "Total, all industries"))
          }

          setEmploymentSize(
            parseUserSetting?.establishment?.geographyInsights?.employmentSize
              ? parseUserSetting?.establishment?.geographyInsights?.employmentSize
              : ""
          );

          setSelectedSector(
            parseUserSetting?.establishment?.geographyInsights?.sectors
              ? parseUserSetting?.establishment?.geographyInsights?.sectors
              : { parents: [], children: [] }
          );
          sectorInsights(parseUserSetting?.establishment?.geographyInsights?.sectors)


          Highcharts.setOptions({
            lang: {
              thousandsSep: ",",
            },
          });

        } catch (error) {
          console.log(error)
        } finally {
          showLoader(false)
        }
      }
      initialize()

    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }, []);

  useEffect(() => {
    if (ready) {
      if (!isLoading && selectedYear) {
        setInsights(false);
        getBusinessCountByGeography();
        updateUserSettings()
        businessSizeRef.current = employmentSize;
        selectYearRef.current = selectedYear;
        selectPeriodRef.current = selectedPeriod || null;
        setOptions((prevOptions) => ({
          ...prevOptions,
          title: {
            ...prevOptions.title,
            text: t("dashboardCardTwoTitle1"),
          },
          subtitle: {
            text: findEmploymentSizeName(),
          },
        }));
        sectorInsights(selectedSector)
      }
    }

  }, [employmentSize, selectedPeriod, selectedYear, selectedSector, windowWidth, t]);

  return (
    <>
      <HelpTour
        run={helpRun}
        steps={joyrideSteps}
        onJoyrideCallback={handleHelpTour}
        
      />
      {/* <div className="row gx-2 d-flex">
        <div className="col d-flex justify-content-end">
          <BiSolidVideos style={{ fontSize: '24px' }} title="Help Videos" onClick={() => setShowVideoModal(true)} />
        </div>
      </div> */}
      <Loader show={show} />

      <div className="row gx-2">
        <div className="col-12 col-md-12 col-xl-3">
          <Card className="filter_card">
            <Card.Body>
              <Card.Title>
                <div className="d-flex justify-content-between align-items-center">
                  <h3>{t("commonHeadingFilters")}</h3>
                  {(employmentSize ||
                    (selectedSector &&
                      (selectedSector.parent?.length ||
                        selectedSector.children?.length)) ||
                    selectedPeriod ||
                    selectedYear) && (
                      <button
                        className="clear_btn"
                        onClick={clearAll}
                        style={{ fontSize: "15px", marginRight: "10px" }}
                      >
                        {t("commonSubHeadingClearAll")}
                      </button>
                    )}
                </div>
              </Card.Title>
              <div className="multiselect-dropdown">
                <div className="row gx-3">
                  <div className="col-12">
                    <div className="custom-form-group custom-form-group-sm">
                      <label
                        htmlFor="employmentSizeSelect"
                        className="text-black"
                        style={{ fontSize: "13px" }}
                      >
                        {t("commonInputTitleEmploymentSize")}:
                      </label>
                      <select
                        className="form-select c-form-control c-form-control-bg c-form-control-sm c-form-control-sm-height fw-semibold"
                        value={employmentSize}
                        onChange={handleEmploymentSizeChange}
                      >
                        <option value={""} selected>
                          {t("businessTypeTotalWithAndWithoutEmployees")}
                        </option>
                        <option value={"null"}>
                          {t("businessTypeTotalWithoutEmployees")}
                        </option>
                        {businessTypeList.map((type) => (
                          <option value={type._id}>
                            {capitalize(t(type.languageKey))}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="row gx-3">
                  <div className="col-12 col-md-6">
                    <div className="custom-form-group custom-form-group-sm">
                      <label
                        htmlFor="periodSelect"
                        className="text-black"
                        style={{ fontSize: "13px" }}
                      >
                        {t("commonInputTitlePeriod")}:
                      </label>
                      <select
                        className="form-select c-form-control c-form-control-bg c-form-control-sm c-form-control-sm-height fw-semibold"
                        // style={{ maxWidth: "350px" }}
                        value={selectedPeriod}
                        onChange={handlePeriodChange}
                      >
                        <option value={""} disabled>
                          {t("commonTextSelect")}{" "}
                        </option>
                        {selectedYear &&
                          selectedYear?.period?.map((item, index) => (
                            <option key={index} value={item.value}>
                              {(() => {
                                switch (item.value) {
                                  case 1:
                                    return t("commonPeriodInputValueJune");
                                  case 2:
                                    return t("commonPeriodInputValueDecember");
                                  default:
                                    return t("commonPeriodInputValueUnknown");
                                }
                              })()}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>

                  <div className="col-12 col-md-6">
                    <div className="custom-form-group custom-form-group-sm">
                      <label
                        htmlFor="yearSelect"
                        className="text-black"
                        style={{ fontSize: "13px" }}
                      >
                        {t("commonInputTitleYear")}:
                      </label>
                      <select
                        className="form-select c-form-control c-form-control-bg c-form-control-sm c-form-control-sm-height fw-semibold"
                        value={JSON.stringify(selectedYear)}
                        onChange={handleYearChange}
                      >
                        <option value={""} disabled>
                          {t("commonTextSelect")}
                        </option>
                        {years?.map((year, index) => (
                          <option key={index} value={JSON.stringify(year)}>
                            {year.year}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              <div className="multiselect-establishment">
                <MultiSelectCheckbox
                  options={establishmentList}
                  onChange={handleSectorChange}
                  multiSelectRef={multiSelectRef}
                  maxHeight={"320px"}
                  selected={selectedSector ? selectedSector : {}}
                />
              </div>
            </Card.Body>
          </Card>
        </div>

        {/* 2 col for maps */}
        <div className="col-12 col-md-12 col-xl-6 establishment-map">
          <Card>
            <Card.Body>
              <HighchartsReact
                highcharts={Highcharts}
                constructorType={"mapChart"}
                options={options}
                ref={chartRef}
              />
            </Card.Body>
          </Card>
        </div>

        {/* 3 col for insights */}
        <div className="col-12 col-xl-3 mt-3 mt-md-2 mt-xl-0">
          <Card className="insight_card">
            <Card.Body>
              <Card.Title>
                <h3>{t("commonTitleInsights")}</h3>
              </Card.Title>
              <ListGroup>
                {insights && (
                  <>
                    <div
                      style={{
                        maxHeight: "300px",
                        overflowY: "scroll",
                        marginBottom: "10px",
                      }}
                    >
                      <ListGroup.Item>
                        <div>
                          {`${t("dashboardTopInsightSentencePartOne")} `}
                          <strong>{totalManufactures}</strong>
                          {` ${t("establishments")} ${t(
                            "dashboardTopInsightSentencePartTwo"
                          )} `}
                          {`${selectBusinessType === ""
                            ? employmentSize === ""
                              ? `${t("dashboardTopInsightWithAndWithoutEmployees")}`
                              : `${t("dashboardTopInsightWithoutEmployees")}`
                            : t(selectBusinessType)}`}
                          {` ${t("withinThe")} `}
                          <strong>{selectedSectorName}</strong>
                          {` ${!selectedSectorName
                            ? t("all") + " " + t("dashboardTopInsightSectors")
                            : selectedSector?.parents?.length > 1
                              ? t("dashboardTopInsightSectors")
                              : t("sector")} ${t("dashboardTopInsightSentencePartThree")}.`}
                        </div>
                      </ListGroup.Item>
                      <div>
                        <ListGroup>
                          {sectorData && sectorData?.length > 1 ? (
                            sectorData
                              ?.sort((a, b) => a.name.localeCompare(b.name))
                              ?.map((item, index) => (
                                <ListGroup.Item key={index}>
                                  <strong>
                                    {capitalizeString(t(item.languageKey))} ({t(
                                      "naics"
                                    )}
                                    {item.externalId})
                                  </strong>
                                  : {item.totalCount}{" "}
                                  {capitalizeString(t("commonInsightsTextBusinesses"))}
                                </ListGroup.Item>
                              ))
                          ) : (
                            sectorData?.[0]?.childEstablishments
                              ?.sort((a, b) => a.name.localeCompare(b.name))
                              ?.map((item, index) => (
                                <ListGroup.Item key={index}>
                                  <strong>
                                    {capitalizeString(t(item.languageKey))} ({t(
                                      "naics"
                                    )}
                                    {item.externalId})
                                  </strong>
                                  : {item.count}{" "}
                                  {capitalizeString(t("commonInsightsTextBusinesses"))}
                                </ListGroup.Item>
                              ))
                          )}
                        </ListGroup>
                      </div>
                    </div>
                  </>
                )}
                {insights && insights.maxBusinessCountSector && (
                  <ListGroup.Item>
                    {t("dashboardInsightsMaxBusinessCountSector")}{" "}
                    {insights.maxBusinessCountSector.establishment.split(",")
                      .length > 1
                      ? t("are")
                      : t("is")}{" "}
                    <strong>
                      {insights.maxBusinessCountSector.establishment
                        .split(",")
                        .map((establishment, index) => (
                          <span key={index}>
                            {capitalizeString(t(establishment.trim()))}
                            {index !==
                              insights.maxBusinessCountSector.establishment.split(
                                ","
                              ).length -
                              1 && ", "}
                          </span>
                        ))}
                    </strong>{" "}
                    {t("with")}{" "}
                    <strong>
                      {insights.maxBusinessCountSector.totalCount}
                    </strong>{" "}
                    {t("commonInsightsTextBusinesses")}{" "}
                    {employmentSize === "" ? t("dashboardInsightsWithAndWithoutEmployees") : employmentSize === "null" ? t("dashboardInsightsWithoutEmployees") : t("dashboardInsightsWithEmployees")}{" "}
                    {t("dashboardInsightsInCanada")}
                  </ListGroup.Item>
                )}
                {insights && insights.minBusinessCountSector && (
                  <ListGroup.Item>
                    {t("dashboardInsightsMinBusinessCountSector")}{" "}
                    {insights.minBusinessCountSector.establishment.split(",")
                      .length > 1
                      ? t("are")
                      : t("is")}{" "}
                    <strong>
                      {insights.minBusinessCountSector.establishment
                        .split(",")
                        .map((establishment, index) => (
                          <span key={index}>
                            {capitalizeString(t(establishment.trim()))}
                            {index !==
                              insights.minBusinessCountSector.establishment.split(
                                ","
                              ).length -
                              1 && ", "}
                          </span>
                        ))}
                    </strong>{" "}
                    {t("with")}{" "}
                    <strong>
                      {insights.minBusinessCountSector.totalCount}
                    </strong>{" "}
                    {t("commonInsightsTextBusinesses")}{" "}
                    {employmentSize === "" ? t("dashboardInsightsWithAndWithoutEmployees") : employmentSize === "null" ? t("dashboardInsightsWithoutEmployees") : t("dashboardInsightsWithEmployees")}{" "}
                    {t("dashboardInsightsInCanada")}
                  </ListGroup.Item>
                )}

                {insights &&
                  (insights.maxBusinessCountProvince ||
                    insights.minBusinessCountProvince) && (
                    <ListGroup.Item className="bg-theme text-white">
                      <h5 className="m-0">
                        {t("dashboardInsightsOutOfSelectedSector")}
                      </h5>
                    </ListGroup.Item>
                  )}
                {insights && insights.maxBusinessCountProvince && (
                  <ListGroup.Item>
                    {t("dashboardInsightsMaxGeograpgyBusinessCount")}{" "}
                    {insights.maxBusinessCountProvince.geography.split(",")
                      .length > 1
                      ? t("are")
                      : t("is")}{" "}
                    <strong>
                      {capitalize(insights.maxBusinessCountProvince.geography)}
                    </strong>{" "}
                    {t("with")}{" "}
                    <strong>
                      {insights.maxBusinessCountProvince.totalCount}
                    </strong>{" "}
                    {t("commonInsightsTextBusinesses")}{" "}
                    {employmentSize === "" ? t("dashboardInsightsWithAndWithoutEmployees") : employmentSize === "null" ? t("dashboardInsightsWithoutEmployees") : t("dashboardInsightsWithEmployees")}{" "}
                    {t("dashboardInsightsInCanada")}
                  </ListGroup.Item>
                )}
                {insights && insights.minBusinessCountProvince && (
                  <ListGroup.Item>
                    {t("dashboardInsightsMinGeograpgyBusinessCount")}{" "}
                    {insights.minBusinessCountProvince.geography.split(",")
                      .length > 1
                      ? t("are")
                      : t("is")}{" "}
                    <strong>
                      {capitalize(insights.minBusinessCountProvince.geography)}
                    </strong>{" "}
                    {t("with")}{" "}
                    <strong>
                      {insights.minBusinessCountProvince.totalCount}
                    </strong>{" "}
                    {t("commonInsightsTextBusinesses")}{" "}
                    {employmentSize === "" ? t("dashboardInsightsWithAndWithoutEmployees") : employmentSize === "null" ? t("dashboardInsightsWithoutEmployees") : t("dashboardInsightsWithEmployees")}{" "}
                    {t("dashboardInsightsInCanada")}
                  </ListGroup.Item>
                )}
                {!insights && (
                  <ListGroup.Item className="text-danger">
                    {t("dashboardInsightsNoValue")}
                  </ListGroup.Item>
                )}
              </ListGroup>
            </Card.Body>
          </Card>
        </div>
      </div>
      <WelcomeHelpModal
        image={dummyImage}
        // videoUrl={dummyVideo}
        show={showHelpTourModal}
        showVideo={() => setShowVideoModal(true)}
        onClose={() => setShowHelpTourModal(false)}
        runHelp={() => setHelpRun(true)}
      />
      {/* <Modal
        show={showHelpTourModal}
        onHide={() => setShowHelpTourModal(false)}
        backdrop="static"
        keyboard={false}
        size="md"
        
      >
       
        <Modal.Body>
          <div>
            <img src={dummyImage} alt="" style={{ width: "100%", height: "300px" }} />
            <h5 style={{ textAlign: "center" }}>Welcome to our guide</h5>
            <p style={{ textAlign: "center" }}>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Illum at unde ex itaque optio quasi possimus ratione tenetur quas deserunt eveniet ipsum, dolorum vero, quae, nihil doloribus labore repudiandae. Obcaecati.</p>
            <div className="d-flex justify-content-end gap-1">
              <button className="button" onClick={() => { setShowVideoModal(true); setShowHelpTourModal(false) }}>Watch our dashboard help video</button>
              <button className="button" onClick={() => { setHelpRun(true); setShowHelpTourModal(false) }}>Go to our guided help tour</button>
            </div>
          </div>
          
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => {setShowHelpTourModal(false);setJoyrideStatus(false)}}>
            Cancel
          </Button>
          
        </Modal.Footer>
      </Modal> */}
      <Modal
        show={showVideoModal}
        onHide={() => setShowVideoModal(false)}
        backdrop="static"
        keyboard={false}
        size="md"
        className="modal-dialog-centered"
      // style={style}
      >
        {/* <Modal.Header >
          <Modal.Title>Help Videos</Modal.Title>
        </Modal.Header> */}
        <Modal.Body>
          {/* <div>
            <img src={dummyImage} alt="" style={{width: "100%", height: "300px"}}/>
            <h5 style={{textAlign: "center"}}>Welcome to our guide</h5>
            <p style={{textAlign: "center"}}>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Illum at unde ex itaque optio quasi possimus ratione tenetur quas deserunt eveniet ipsum, dolorum vero, quae, nihil doloribus labore repudiandae. Obcaecati.</p>
            <div className="d-flex justify-content-end gap-1">
            <button className="button">Watch our dashboard help video</button>
              <button className="button" onClick={() => {setHelpRun(true);setShowHelpTourModal(false)}}>Go to our guided help tour</button>
            </div>
          </div> */}
          <div className="d-flex gap-2 justify-content-around">
            {/* <div className="card" style={{ width: '18rem' }}>
              <img className="card-img-top" src={dummyImage} alt="Card image cap" />
              <div className="card-body">
                <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
              </div>
            </div> */}
            <div style={{ width: '100%', height: "300px" }}>
              <ReactPlayer
                // light={<img src={dummyImage} alt='Thumbnail' style={{width: '100%', height: '100%', objectFit: 'cover'}}/>}
                url={""}
                controls={true}
                playing={playing}
                onClickPreview={() => setPlaying(true)}
                width="100%" // Adjust width
                height="100%" // Adjust height
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => { setShowVideoModal(false); setJoyrideStatus(false) }}>
            Cancel
          </Button>
          {/* <Button variant="primary" onClick={confirmEventTriggered}>Confirm</Button> */}
        </Modal.Footer>
      </Modal>

    </>
  );
};

export default Dashboard;

const CustomIconWithTooltip = ({ iconContainerId, tooltipId, onStart }) => {
  const { t } = useTranslation()
  return (
    <>
      {/* First Icon with Tooltip */}
      <div id={`${iconContainerId}-1`} className="custom-icon" style={{ position: 'relative' }}>
        <OverlayTrigger
          placement="bottom"
          overlay={(props) => (
            <Tooltip {...props} className="tooltip-justify" >
              {/* <div style={{background: "#fff", borderRadius: "15px", width: "100%", padding:"15px", marginBottom:"5px"}}>
                    <img src={FPSCLogo} alt="" width={"100%"} height={100}/>
                </div> */}
              <b>{t("disclaimer")}:</b>  {t('disclaimerText').split('\\n').map((line, index, array) => (
                <React.Fragment key={index}>
                  {line}
                  {index < array.length - 1 && (
                    <div style={{ height: '4px' }} />)}
                </React.Fragment>
              ))}
            </Tooltip>
          )}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: '4px',
              width: "30px",
              height: "30px",
              backgroundColor: "#f0f0f0",
              cursor: 'pointer'
            }}
          >
            <FaInfoCircle style={{ fontSize: '20px', color: "#212529" }} />
          </div>
        </OverlayTrigger>
      </div>

      {/* Second Icon with Tooltip */}
      {/* <div id={`${iconContainerId}-2`} className="custom-icon" style={{ position: 'relative' }}>
        <OverlayTrigger
          placement="bottom"
          overlay={(props) => (
            <Tooltip {...props} className="tooltip-justify">
              {t('textClickHelp')}
            </Tooltip>
          )}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: '4px',
              width: "30px",
              height: "30px",
              backgroundColor: "#f0f0f0",
              cursor: 'pointer'
            }}
          >
            <FaQuestionCircle style={{ fontSize: '20px', color: "#212529" }} onClick={() => onStart(true)} />
          </div>
        </OverlayTrigger>
      </div> */}
    </>
  );
};
