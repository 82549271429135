import Dashboard from "../pages/dashboard/Dashboard.tsx";
import Login from "../pages/auth/Login.tsx";
import Documents from "../pages/documents/Documents.tsx";
import Dashboard2 from "../pages/dashboard/Dashboard2.tsx";
import Comparison from "../pages/dashboard/Comparison.tsx";
import ChangePassword from "../pages/settings/ChangePassword.tsx";
import AddUser from '../pages/settings/AddUser.tsx'
import EducationalProgram from "../pages/dashboard/EducationalProgram";
import EmploymentData from "../pages/dashboard/EmploymentData";
import HorizontalChartEducationalProgram from "../components/EducationalProgramTabComponents/HorizontalChartEducationalProgram.js";
import EstablishmentData from "../pages/dashboard/EstablishmentData.js";
import Settings from "../pages/settings/Settings.tsx"
import SetPassword from "../pages/SetPassword.tsx";

const Routes: any = [
  {
    component: Login,
    path: "/",
    title: "User Login",
    to: "/",
    type: "public",
    children: [],
  },
  {
    component: SetPassword,
    path: "/set-password",
    title: "Set Password",
    to: "/set-password",
    type: "public",
    children: [],
  },
  {
    component: Dashboard,
    path: "/geography-insights",
    title: "Geography Insights",
    to: "/",
    type: "private",
    children: [],
    meta: [],
  },
  {
    component: Dashboard2,
    path: "/sector-insights",
    title: "Sector Insights",
    to: "/",
    type: "private",
    children: [],
    meta: [],
  },
  {
    component: Comparison,
    path: "/comparison",
    title: "Comparison",
    to: "/",
    type: "private",
    children: [],
    meta: [],
  },

  {
    component: Documents,
    path: "/documents",
    title: "Documents",
    to: "/",
    type: "private",
    children: [],
    meta: [],
  },
  {
    component: AddUser,
    path: "/add-user",
    title: "Users",
    to: "/",
    type: "private",
    children: [],
    meta: [],
  },
  {
    component: Settings,
    path: "/settings",
    title: "Settings",
    to: "/",
    type: "private",
    children: [],
    meta: [],
  },
  {
    component: ChangePassword,
    path: "/change-password",
    title: "Change Password",
    to: "/",
    type: "private",
    children: [],
    meta: [],
  },
  {
    component: EducationalProgram,
    path: "/educational-program",
    title: "Educational Program",
    to: "/",
    type: "private",
    children: [],
    meta: [],
  },
  {
    component: EmploymentData,
    path: "/employment-data",
    title: "Employment Data",
    to: "/",
    type: "private",
    children: [],
    meta: [],
  },
  {
    component: EstablishmentData,
    path: "/establishment-data",
    title: "establishment Data",
    to: "/",
    type: "private",
    children: [],
    meta: [],
  },
];
export default Routes;
