import React, { useState, useEffect } from 'react'
import Joyride from 'react-joyride'
import useWindowWidth from '../hooks/useWindowWidth';

const HelpTour = ({ run, steps, onJoyrideCallback }) => {
  const windowWidth = useWindowWidth()
  const [joyrideKey, setJoyrideKey] = useState(0)
  const [tourStyles, setTourStyles] = useState(
    {
      options: {
        arrowColor: '#fff',   //#e3ffeb
        backgroundColor: '#fff',
        overlayColor: 'rgba(0,0,0,0.5)',
        primaryColor: '#e61e28',
        textColor: '#000',
        width: 400, // Reduced width
        zIndex: 1000,
      },
      spotlight: {
        overflow: 'visible', // Ensure spotlight allows for overflow content
      },
      tooltip: {
        left: 'auto', // Reset to ensure it's auto-positioned
        right: 'auto',
        // height: "160px"
      },
      tooltipContainer: {
        textAlign: 'justify',
        fontSize: 16,
      },
      floater: {
        padding: 0 , // Remove extra padding
        margin: 0,  // Remove extra margin
        boxShadow: 'none', // Remove default box shadow if unnecessary
      },
    }
  )

  const handleJoyrideCallback = (data) => {
    const { action, index, origin, status, type } = data;
    onJoyrideCallback(data)
    if (action === 'reset' || action === 'close') {
      setJoyrideKey((prevKey) => prevKey + 1);
    }
  };

  useEffect(() => {
    if (windowWidth <= 768) {
      setTourStyles(prevOptions => ({
        ...prevOptions,
        tooltip:{
          ...prevOptions.tooltip,
          // height: '170px'
        },
        tooltipContainer: {
          ...prevOptions.tooltipContainer,
          fontSize: 12,
          // maxHeight: 150, // Apply maxHeight only on mobile
          overflowY: 'auto',

        },
        tooltipFooter: {
          ...prevOptions?.tooltipFooter,
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'flex-start',
          marginTop: 0,
        },
      }))
    } else {
      setTourStyles(prevOptions => ({
        ...prevOptions,
        tooltip:{
          ...prevOptions.tooltip,
          // height: '170px'
        },
        tooltipContainer: {
          ...prevOptions.tooltipContainer,
          fontSize: 16,
        },
        tooltipFooter: {
          ...prevOptions?.tooltipFooter,
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'flex-end',
          marginTop: 0,
        },
      }))
    }
  }, [windowWidth])

  return (
    <>
      <Joyride
        callback={handleJoyrideCallback}
        run={run}
        steps={steps}
        key={joyrideKey}
        continuous={true} // Enables automatic progression between steps with "Next" and "Back"
        locale={{
          last: 'Close', // Changes the text of the last button to "Close"
          next: 'Next',
          back: 'Back',
        }}
        overlay={{
          overflow: 'scroll'
        }

        }
        styles={tourStyles}
      />
    </>
  )
}

export default HelpTour